export const allconnections = [
  {
    id: 'salesforce',
    name: 'Salesforce',
    iconPath: '/integrations/salesforce/resources/salesforce.svg',
    type: 'Sales',
  },
  {
    id: 'google_ads',
    name: 'Google Ads',
    iconPath: '/integrations/google_ads/resources/google-ads.png',
    type: 'Marketing',
  },
  {
    id: 'shopify',
    name: 'Shopify',
    iconPath: '/integrations/shopify/resources/shopify.svg',
    type: 'Sales',
  },
  {
    id: 's3',
    name: 'Amazon S3',
    iconPath: '/integrations/s3/resources/s3.svg',
    type: 'File',
  },
  {
    id: 'facebook_ads',
    name: 'Facebook Ads',
    iconPath: '/integrations/facebook/resources/facebook.svg',
    type: 'Marketing',
  },
  {
    id: 'google_analytics_4',
    name: 'Google Analytics 4',
    iconPath: '/integrations/google_analytics_4/resources/google_analytics_4.svg',
    type: 'Marketing',
  },
  {
    id: 'google_cloud_function',
    name: 'Google Cloud Functions',
    iconPath: '/integrations/functions/google_cloud_function/resources/logo.svg',
    type: 'Function',
  },
  {
    id: 'google_drive',
    name: 'Google Drive',
    iconPath: '/integrations/gdrive/resources/google-drive-icon.svg',
    type: 'File',
  },
  {
    id: 'postgres_rds',
    name: 'Postgres RDS',
    iconPath: '/integrations/postgres2020/resources/postgres.svg',
    type: 'Database',
  },
  {
    id: 'google_analytics',
    name: 'Google Analytics',
    iconPath: '/integrations/google_analytics/resources/google_analytics.svg',
    type: 'Marketing',
  },
  {
    id: 'google_sheets',
    name: 'Google Sheets',
    iconPath: '/integrations/gsheets/resources/gsheets.svg',
    type: 'File',
  },
  {
    id: 'sql_server',
    name: 'SQL Server',
    iconPath: '/integrations/sql_server/resources/sql_server.svg',
    type: 'Database',
  },
  {
    id: 'fivetran_log',
    name: 'Fivetran Log',
    iconPath: '/integrations/fivetran_log/resources/fivetran.svg',
    type: 'Free',
  },
  {
    id: 'azure_blob_storage',
    name: 'Azure Blob Storage',
    iconPath: '/integrations/azure_blob_storage/resources/azure_blob_storage.svg',
    type: 'File',
  },
  {
    id: 'hubspot',
    name: 'HubSpot',
    iconPath: '/integrations/hubspot/resources/hubspot.svg',
    type: 'Marketing',
  },
  {
    id: 'aws_lambda',
    name: 'AWS Lambda',
    iconPath: '/integrations/functions/aws_lambda/resources/logo.png',
    type: 'Function',
  },
  {
    id: 'tiktok_ads',
    name: 'Tiktok Ads',
    iconPath: '/integrations/tiktok_ads/resources/tiktok-logo.svg',
    type: 'Marketing',
  },
  {
    id: 'share_point',
    name: 'SharePoint',
    iconPath: '/integrations/azure_consumer_file/resources/share_point.svg',
    type: 'File',
  },
  {
    id: 'webhooks',
    name: 'Webhooks',
    iconPath: '/integrations/webhooks/resources/webhooks.svg',
    type: 'Events',
  },
  {
    id: 'klaviyo',
    name: 'Klaviyo',
    iconPath: '/integrations/klaviyo/resources/klaviyo.svg',
    type: 'Marketing',
  },
  {
    id: 'gcs',
    name: 'Google Cloud Storage',
    iconPath: '/integrations/gcs/resources/gcs.svg',
    type: 'File',
  },
  {
    id: 'stripe',
    name: 'Stripe',
    iconPath: '/integrations/stripe/resources/stripe.svg',
    type: 'Finance',
  },
  {
    id: 'azure_sql_db',
    name: 'Azure SQL Database',
    iconPath: '/integrations/sql_server/resources/azure.png',
    type: 'Database',
  },
  {
    id: 'aurora_postgres',
    name: 'Aurora Postgres',
    iconPath: '/integrations/postgres2020/resources/aurora.png',
    type: 'Database',
  },
  {
    id: 'mongo',
    name: 'MongoDB',
    iconPath: '/integrations/mongo/resources/mongo.svg',
    type: 'Database',
  },
  {
    id: 'quickbooks',
    name: 'QuickBooks',
    iconPath: '/integrations/quickbooks/resources/quickbooks.svg',
    type: 'Finance',
  },
  {
    id: 'bingads',
    name: 'Microsoft Advertising',
    iconPath: '/integrations/bingads/resources/bingads.svg',
    type: 'Marketing',
  },
  {
    id: 'youtube_analytics',
    name: 'YouTube Analytics',
    iconPath: '/integrations/youtube_analytics/resources/youtube_analytics.svg',
    type: 'Marketing',
  },
  {
    id: 'facebook_pages',
    name: 'Facebook Pages',
    iconPath: '/integrations/facebook/resources/facebook.svg',
    type: 'Marketing',
  },
  {
    id: 'salesforce_sandbox',
    name: 'Salesforce sandbox',
    iconPath: '/integrations/salesforce/resources/salesforce_sandbox.png',
    type: 'Sales',
  },
  {
    id: 'mysql_rds',
    name: 'MySQL RDS',
    iconPath: '/integrations/mysql/resources/logos/mysql.png',
    type: 'Database',
  },
  {
    id: 'workday',
    name: 'Workday RaaS',
    iconPath: '/integrations/workday/resources/workday.png',
    type: 'HumanResources',
  },
  {
    id: 'aurora',
    name: 'Aurora MySQL',
    iconPath: '/integrations/mysql/resources/logos/aurora.png',
    type: 'Database',
  },
  {
    id: 'github',
    name: 'GitHub',
    iconPath: '/integrations/github/resources/github.svg',
    type: 'Engineering',
  },
  {
    id: 'snowflake_db',
    name: 'Snowflake',
    iconPath: '/integrations/snowflake/resources/snowflake.png',
    type: 'Database',
  },
  {
    id: 'netsuite_suiteanalytics',
    name: 'NetSuite SuiteAnalytics',
    iconPath: '/integrations/netsuite/resources/netsuite.svg',
    type: 'Finance',
  },
  {
    id: 'google_cloud_postgresql',
    name: 'Google Cloud PostgreSQL',
    iconPath: '/integrations/postgres2020/resources/cloudsql.png',
    type: 'Database',
  },
  {
    id: 'linkedin_ads',
    name: 'LinkedIn Ad Analytics',
    iconPath: '/integrations/linkedin/resources/linkedin_ads.svg',
    type: 'Marketing',
  },
  {
    id: 'azure_function',
    name: 'Azure Functions',
    iconPath: '/integrations/functions/azure_function/resources/logo.svg',
    type: 'Function',
  },
  {
    id: 'xero',
    name: 'Xero',
    iconPath: '/integrations/xero/resources/xero.svg',
    type: 'Finance',
  },
  {
    id: 'instagram_business',
    name: 'Instagram Business',
    iconPath: '/integrations/instagram/resources/instagram.png',
    type: 'Marketing',
  },
  {
    id: 'jira',
    name: 'Jira',
    iconPath: '/integrations/jira/resources/jira.svg',
    type: 'Engineering',
  },
  {
    id: 'google_search_console',
    name: 'Google Search Console',
    iconPath: '/integrations/google_search_console/resources/google-search-console-logo.svg',
    type: 'Marketing',
  },
  {
    id: 'pinterest_ads',
    name: 'Pinterest Ads',
    iconPath: '/integrations/pinterest/resources/pinterest-ads.svg',
    type: 'Marketing',
  },
  {
    id: 'zendesk',
    name: 'Zendesk Support',
    iconPath: '/integrations/zendesk/resources/zendesk.svg',
    type: 'Support',
  },
  {
    id: 'postgres',
    name: 'Postgres',
    iconPath: '/integrations/postgres2020/resources/postgres.svg',
    type: 'Database',
  },
  {
    id: 'mysql',
    name: 'MySQL',
    iconPath: '/integrations/mysql/resources/logos/mysql.png',
    type: 'Database',
  },
  {
    id: 'oracle',
    name: 'Oracle',
    iconPath: '/integrations/oracle/resources/oracle.svg',
    type: 'Database',
  },
  {
    id: 'salesforce_marketing_cloud',
    name: 'Salesforce Marketing Cloud',
    iconPath: '/integrations/salesforce_marketing_cloud/resources/salesforce_marketing_cloud.svg',
    type: 'Marketing',
  },
  {
    id: 'dynamodb',
    name: 'DynamoDB',
    iconPath: '/integrations/dynamodb/resources/dynamodb.svg',
    type: 'Database',
  },
  {
    id: 'amazon_selling_partner',
    name: 'Amazon Selling Partner',
    iconPath: '/integrations/amazon_selling_partner/resources/logo.svg',
    type: 'Sales',
  },
  {
    id: 'linkedin_company_pages',
    name: 'LinkedIn Company Pages',
    iconPath: '/integrations/linkedin/resources/linkedin_company_pages.svg',
    type: 'Marketing',
  },
  {
    id: 'sql_server_hva',
    name: 'High-Volume Agent SQL Server',
    iconPath: '/integrations/sql_server_hva/resources/sql_server.svg',
    type: 'Database',
  },
  {
    id: 'google_display_and_video_360',
    name: 'Google Display & Video 360',
    iconPath: '/integrations/google_display_and_video_360/resources/google-display-and-video-360.svg',
    type: 'Marketing',
  },
  {
    id: 'snapchat_ads',
    name: 'Snapchat Ads',
    iconPath: '/integrations/snapchat_ads/resources/snapchat.png',
    type: 'Marketing',
  },
  {
    id: 'sql_server_rds',
    name: 'SQL Server RDS',
    iconPath: '/integrations/sql_server/resources/sql_server.svg',
    type: 'Database',
  },
  {
    id: 'amazon_ads',
    name: 'Amazon Ads',
    iconPath: '/integrations/amazon_ads/resources/amazon-ads-logo.svg',
    type: 'Marketing',
  },
  {
    id: 'box',
    name: 'Box',
    iconPath: '/integrations/box/resources/box-icon.svg',
    type: 'File',
  },
  {
    id: 'intercom',
    name: 'Intercom',
    iconPath: '/integrations/intercom/resources/intercom.svg',
    type: 'Support',
  },
  {
    id: 'dropbox',
    name: 'Dropbox',
    iconPath: '/integrations/dropbox/resources/dropbox.svg',
    type: 'File',
  },
  {
    id: 'itunes_connect',
    name: 'Apple App Store',
    iconPath: '/integrations/itunes_connect/resources/itunes_connect_logo.png',
    type: 'Marketing',
  },
  {
    id: 'heroku_postgres',
    name: 'Heroku Postgres',
    iconPath: '/integrations/postgres2020/resources/heroku.png',
    type: 'Database',
  },
  {
    id: 'typeform',
    name: 'Typeform',
    iconPath: '/integrations/typeform/resources/typeform.svg',
    type: 'Support',
  },
  {
    id: 'bigquery_db',
    name: 'BigQuery',
    iconPath: '/integrations/bigquery/resources/logo.png',
    type: 'Database',
  },
  {
    id: 'google_analytics_4_export',
    name: 'Google Analytics 4 Export',
    iconPath: '/integrations/google_analytics_4_export/resources/google-analytics-4-export.svg',
    type: 'Marketing',
  },
  {
    id: 'dynamics_365',
    name: 'Microsoft Dynamics 365 CRM',
    iconPath: '/integrations/dynamics365/resources/logo.svg',
    type: 'Sales',
  },
  {
    id: 'mailchimp',
    name: 'Mailchimp',
    iconPath: '/integrations/mailchimp/resources/mailchimp.svg',
    type: 'Marketing',
  },
  {
    id: 'recharge',
    name: 'Recharge',
    iconPath: '/integrations/recharge/resources/recharge.svg',
    type: 'Finance',
  },
  {
    id: 'cosmos',
    name: 'Azure Cosmos DB - NoSQL API',
    iconPath: '/integrations/cosmos/resources/cosmos.svg',
    type: 'Database',
  },
  {
    id: 'pendo',
    name: 'Pendo',
    iconPath: '/integrations/pendo/resources/pendo.svg',
    type: 'Events',
  },
  {
    id: 'twitter_ads',
    name: 'Twitter Ads',
    iconPath: '/integrations/twitter/resources/twitter_ads.svg',
    type: 'Marketing',
  },
  {
    id: 'google_play',
    name: 'Google Play',
    iconPath: '/integrations/google_play/resources/google_play.svg',
    type: 'Marketing',
  },
  {
    id: 'amplitude',
    name: 'Amplitude',
    iconPath: '/integrations/amplitude/resources/logo.svg',
    type: 'Events',
  },
  {
    id: 'asana',
    name: 'Asana',
    iconPath: '/integrations/asana/resources/asana.svg',
    type: 'Productivity',
  },
  {
    id: 'double_click_campaign_manager',
    name: 'Google Campaign Manager 360',
    iconPath: '/integrations/double_click_campaign_manager/resources/campaign_manager.svg',
    type: 'Marketing',
  },
  {
    id: 'iterable',
    name: 'Iterable',
    iconPath: '/integrations/iterable/resources/iterable.svg',
    type: 'Marketing',
  },
  {
    id: 'pipedrive',
    name: 'Pipedrive',
    iconPath: '/integrations/pipedrive/resources/pipedrive.svg',
    type: 'Sales',
  },
  {
    id: 'oracle_hva',
    name: 'High-Volume Agent Oracle',
    iconPath: '/integrations/oracle/resources/oracle.svg',
    type: 'Database',
  },
  {
    id: 'salesforce_commerce_cloud',
    name: 'Salesforce Commerce Cloud',
    iconPath: '/integrations/salesforce_commerce_cloud/resources/salesforce_commerce_cloud.svg',
    type: 'Finance',
  },
  {
    id: 'mixpanel',
    name: 'Mixpanel',
    iconPath: '/integrations/mixpanel/resources/mixpanel.svg',
    type: 'Events',
  },
  {
    id: 'twitter',
    name: 'Twitter Organic',
    iconPath: '/integrations/twitter/resources/twitter_ads.svg',
    type: 'Marketing',
  },
  {
    id: 'google_cloud_mysql',
    name: 'Google Cloud MySQL',
    iconPath: '/integrations/mysql/resources/logos/cloudsql.png',
    type: 'Database',
  },
  {
    id: 'airtable',
    name: 'Airtable',
    iconPath: '/integrations/airtable/resources/logo.svg',
    type: 'Productivity',
  },
  {
    id: 'facebook_ad_account',
    name: 'Facebook Ad Account',
    iconPath: '/integrations/facebook/resources/facebook.svg',
    type: 'Marketing',
  },
  {
    id: 'qualtrics',
    name: 'Qualtrics',
    iconPath: '/integrations/qualtrics/resources/qualtrics.svg',
    type: 'Support',
  },
  {
    id: 'monday',
    name: 'Monday.com',
    iconPath: '/integrations/coil_connectors/resources/monday/resources/monday.svg',
    type: 'Engineering',
  },
  {
    id: 'outreach',
    name: 'Outreach',
    iconPath: '/integrations/outreach/resources/outreach.svg',
    type: 'Sales',
  },
  {
    id: 'oracle_rac',
    name: 'Oracle RAC',
    iconPath: '/integrations/oracle/resources/oracle.svg',
    type: 'Database',
  },
  {
    id: 'sage_intacct',
    name: 'Sage Intacct',
    iconPath: '/integrations/sage_intacct/resources/sage_intacct_logo.svg',
    type: 'Finance',
  },
  {
    id: 'marketo',
    name: 'Marketo',
    iconPath: '/integrations/marketo/resources/marketo.svg',
    type: 'Marketing',
  },
  {
    id: 'segment',
    name: 'Segment',
    iconPath: '/integrations/segment/resources/segment.svg',
    type: 'Events',
  },
  {
    id: 'slack',
    name: 'Slack',
    iconPath: '/integrations/coil_connectors/resources/slack/resources/slack.svg',
    type: 'Productivity',
  },
  {
    id: 'twilio',
    name: 'Twilio',
    iconPath: '/integrations/twilio/resources/twilio.svg',
    type: 'Engineering',
  },
  {
    id: 'oracle_fusion_cloud_apps_fscm',
    name: 'Oracle Fusion Cloud Applications - FSCM(ERP & SCM)',
    iconPath: '/integrations/oracle_fusion_cloud_apps/resources/oracle.svg',
    type: 'Finance',
  },
  {
    id: 'workday_hcm',
    name: 'Workday HCM',
    iconPath: '/integrations/workday_hcm/resources/workday.png',
    type: 'HumanResources',
  },
  {
    id: 'apple_search_ads',
    name: 'Apple Search Ads',
    iconPath: '/integrations/apple_search_ads/resources/apple_search_ads.svg',
    type: 'Marketing',
  },
  {
    id: 'survey_monkey',
    name: 'SurveyMonkey',
    iconPath: '/integrations/survey_monkey/resources/survey_monkey.svg',
    type: 'Support',
  },
  {
    id: 'maria',
    name: 'MariaDB',
    iconPath: '/integrations/mysql/resources/logos/maria.png',
    type: 'Database',
  },
  {
    id: 'google_analytics_mcf',
    name: 'Google Analytics (MCF)',
    iconPath: '/integrations/google_analytics/resources/google_analytics_mcf.svg',
    type: 'Marketing',
  },
  {
    id: 'oracle_rds',
    name: 'Oracle RDS',
    iconPath: '/integrations/oracle/resources/oracle.svg',
    type: 'Database',
  },
  {
    id: 'delighted',
    name: 'Delighted',
    iconPath: '/integrations/delighted/resources/delighted.svg',
    type: 'Support',
  },
  {
    id: 'microsoft_lists',
    name: 'Microsoft Lists',
    iconPath: '/integrations/microsoft_lists/resources/microsoft-lists-icon.svg',
    type: 'Engineering',
  },
  {
    id: 'criteo',
    name: 'Criteo',
    iconPath: '/integrations/criteo/resources/criteo.png',
    type: 'Marketing',
  },
  {
    id: 'sendgrid',
    name: 'SendGrid',
    iconPath: '/integrations/sendgrid/resources/sendgrid.svg',
    type: 'Marketing',
  },
  {
    id: 'calendly',
    name: 'Calendly',
    iconPath: '/integrations/coil_connectors/resources/calendly/resources/calendly.svg',
    type: 'Productivity',
  },
  {
    id: 'okta',
    name: 'Okta',
    iconPath: '/integrations/coil_connectors/resources/okta/resources/okta.svg',
    type: 'Productivity',
  },
  {
    id: 'facebook',
    name: 'Facebook Ad Insights',
    iconPath: '/integrations/facebook/resources/facebook.svg',
    type: 'Marketing',
  },
  {
    id: 'braze',
    name: 'Braze',
    iconPath: '/integrations/braze/resources/braze.png',
    type: 'Marketing',
  },
  {
    id: 'activecampaign',
    name: 'ActiveCampaign',
    iconPath: '/integrations/coil_connectors/resources/activecampaign/resources/activecampaign.svg',
    type: 'Marketing',
  },
  {
    id: 'aircall',
    name: 'Aircall',
    iconPath: '/integrations/coil_connectors/resources/aircall/resources/aircall.svg',
    type: 'Marketing',
  },
  {
    id: 'stripe_test',
    name: 'Stripe test mode',
    iconPath: '/integrations/stripe/resources/stripe.svg',
    type: 'Finance',
  },
  {
    id: 'gongio',
    name: 'Gong',
    iconPath: '/integrations/coil_connectors/resources/gongio/resources/gongio.svg',
    type: 'Productivity',
  },
  {
    id: 'pardot',
    name: 'Pardot',
    iconPath: '/integrations/pardot/resources/pardot.svg',
    type: 'Marketing',
  },
  {
    id: 'woocommerce',
    name: 'WooCommerce',
    iconPath: '/integrations/coil_connectors/resources/woocommerce/resources/woocommerce.svg',
    type: 'Marketing',
  },
  {
    id: 'double_click_publishers',
    name: 'Google Ad Manager',
    iconPath: '/integrations/google_ad_manager/resources/google_ad_manager.png',
    type: 'Marketing',
  },
  {
    id: 'greenhouse',
    name: 'Greenhouse',
    iconPath: '/integrations/greenhouse/resources/greenhouse.svg',
    type: 'HumanResources',
  },
  {
    id: 'square',
    name: 'Square',
    iconPath: '/integrations/square/resources/square.png',
    type: 'Finance',
  },
  {
    id: 'sap_hana_db',
    name: 'SAP ERP on HANA',
    iconPath: '/integrations/sap_hana_db/resources/sap_hana_db.svg',
    type: 'Database',
  },
  {
    id: 'bamboohr',
    name: 'Bamboo HR',
    iconPath: '/integrations/bamboohr/resources/bamboohr.svg',
    type: 'HumanResources',
  },
  {
    id: 'gainsight_customer_success',
    name: 'Gainsight Customer Success',
    iconPath: '/integrations/gainsight_customer_success/resources/gainsight_customer_success.svg',
    type: 'Productivity',
  },
  {
    id: 'servicenow',
    name: 'ServiceNow',
    iconPath: '/integrations/service_now/resources/logo.svg',
    type: 'Productivity',
  },
  {
    id: 'chargebee_product_catalog_2',
    name: 'Chargebee Product Catalog 2.0',
    iconPath:
      '/integrations/coil_connectors/resources/chargebee_product_catalog_2/resources/chargebee_product_catalog_2.svg',
    type: 'Finance',
  },
  {
    id: 'impact',
    name: 'Impact',
    iconPath: '/integrations/coil_connectors/resources/impact/resources/impact.svg',
    type: 'Marketing',
  },
  {
    id: 'adobe_analytics',
    name: 'Adobe Analytics',
    iconPath: '/integrations/adobe_analytics/resources/adobe-analytics-symbol.svg',
    type: 'Marketing',
  },
  {
    id: 'azure_postgres',
    name: 'Azure PostgreSQL Database',
    iconPath: '/integrations/postgres2020/resources/azure.png',
    type: 'Database',
  },
  {
    id: 'clickup',
    name: 'ClickUp',
    iconPath: '/integrations/coil_connectors/resources/clickup/resources/clickup.svg',
    type: 'Engineering',
  },
  {
    id: 'appsflyer',
    name: 'AppsFlyer',
    iconPath: '/integrations/appsflyer/resources/appsflyer.svg',
    type: 'Marketing',
  },
  {
    id: 'freshdesk',
    name: 'Freshdesk',
    iconPath: '/integrations/freshdesk/resources/freshdesk.svg',
    type: 'Support',
  },
  {
    id: 'mongo_sharded',
    name: 'MongoDB Sharded',
    iconPath: '/integrations/mongo/resources/mongo.svg',
    type: 'Database',
  },
  {
    id: 'azure_sql_managed_db',
    name: 'Azure SQL Managed Instance',
    iconPath: '/integrations/sql_server/resources/azure.png',
    type: 'Database',
  },
  {
    id: 'open_exchange_rates',
    name: 'Open Exchange Rates',
    iconPath: '/integrations/coil_connectors/resources/open_exchange_rates/resources/open_exchange_rates.svg',
    type: 'Finance',
  },
  {
    id: 'taboola',
    name: 'Taboola',
    iconPath: '/integrations/taboola/resources/taboola.svg',
    type: 'Marketing',
  },
  {
    id: 'paypal',
    name: 'Paypal',
    iconPath: '/integrations/paypal/resources/paypal.svg',
    type: 'Finance',
  },
  {
    id: 'zuora_sandbox',
    name: 'Zuora Sandbox',
    iconPath: '/integrations/zuora/resources/zuora.svg',
    type: 'Finance',
  },
  {
    id: 'maria_rds',
    name: 'MariaDB RDS',
    iconPath: '/integrations/mysql/resources/logos/maria.png',
    type: 'Database',
  },
  {
    id: 'braintree',
    name: 'Braintree',
    iconPath: '/integrations/braintree/resources/braintree.svg',
    type: 'Finance',
  },
  {
    id: 'front',
    name: 'Front',
    iconPath: '/integrations/front/resources/front.svg',
    type: 'Support',
  },
  {
    id: 'reddit_ads',
    name: 'Reddit Ads',
    iconPath: '/integrations/reddit/resources/reddit.svg',
    type: 'Marketing',
  },
  {
    id: 'zoho_crm',
    name: 'Zoho CRM',
    iconPath: '/integrations/zoho_crm/resources/zoho.svg',
    type: 'Support',
  },
  {
    id: 'zendesk_chat',
    name: 'Zendesk Chat',
    iconPath: '/integrations/zendesk_chat/resources/zendesk_chat.svg',
    type: 'Support',
  },
  {
    id: 'loop',
    name: 'loop',
    iconPath: '/integrations/coil_connectors/resources/loop/resources/loop.svg',
    type: 'Sales',
  },
  {
    id: 'salesloft',
    name: 'Salesloft',
    iconPath: '/integrations/salesloft/resources/salesloft.svg',
    type: 'Sales',
  },
  {
    id: 'db2i_hva',
    name: 'High Volume Agent Db2 for i',
    iconPath: '/integrations/db2i_hva/resources/icon-db2i.svg',
    type: 'Database',
  },
  {
    id: 'confluent_cloud',
    name: 'Confluent Cloud',
    iconPath: '/integrations/kafka/resources/confluent_cloud.svg',
    type: 'Events',
  },
  {
    id: 'zuora',
    name: 'Zuora',
    iconPath: '/integrations/zuora/resources/zuora.svg',
    type: 'Finance',
  },
  {
    id: 'ringcentral',
    name: 'RingCentral',
    iconPath: '/integrations/ringcentral/resources/ringcentral.svg',
    type: 'Support',
  },
  {
    id: 'big_commerce',
    name: 'Big Commerce',
    iconPath: '/integrations/big_commerce/resources/big_commerce.svg',
    type: 'Sales',
  },
  {
    id: 'kustomer',
    name: 'Kustomer',
    iconPath: '/integrations/kustomer/resources/kustomer.svg',
    type: 'Support',
  },
  {
    id: 'one_drive',
    name: 'OneDrive',
    iconPath: '/integrations/azure_consumer_file/resources/one_drive.svg',
    type: 'File',
  },
  {
    id: 'branch',
    name: 'Branch',
    iconPath: '/integrations/branch/resources/branch.svg',
    type: 'Marketing',
  },
  {
    id: 'anaplan',
    name: 'Anaplan',
    iconPath: '/integrations/anaplan/resources/anaplan.svg',
    type: 'Finance',
  },
  {
    id: 'freshteam',
    name: 'FreshTeam',
    iconPath: '/integrations/coil_connectors/resources/freshteam/resources/freshteam.svg',
    type: 'HumanResources',
  },
  {
    id: 'coupa',
    name: 'Coupa',
    iconPath: '/integrations/coupa/resources/coupa_logo.svg',
    type: 'Finance',
  },
  {
    id: 'eloqua',
    name: 'Eloqua',
    iconPath: '/integrations/eloqua/resources/eloqua.svg',
    type: 'Marketing',
  },
  {
    id: 'outbrain',
    name: 'Outbrain',
    iconPath: '/integrations/outbrain/resources/outbrain_logo.png',
    type: 'Marketing',
  },
  {
    id: 'alchemer',
    name: 'Alchemer',
    iconPath: '/integrations/coil_connectors/resources/alchemer/resources/alchemer.svg',
    type: 'Support',
  },
  {
    id: 'recurly',
    name: 'Recurly',
    iconPath: '/integrations/recurly/resources/recurly.svg',
    type: 'Finance',
  },
  {
    id: 'google_calendar',
    name: 'Google Calendar',
    iconPath: '/integrations/coil_connectors/resources/google_calendar/resources/google_calendar.svg',
    type: 'Productivity',
  },
  {
    id: 'harvest',
    name: 'Harvest',
    iconPath: '/integrations/coil_connectors/resources/harvest/resources/harvest.svg',
    type: 'Productivity',
  },
  {
    id: 'oracle_fusion_cloud_apps_crm',
    name: 'Oracle Fusion Cloud Applications - CRM',
    iconPath: '/integrations/oracle_fusion_cloud_apps/resources/oracle.svg',
    type: 'Sales',
  },
  {
    id: 'adp_workforce_now',
    name: 'ADP Workforce Now',
    iconPath: '/integrations/adp_workforce_now/resources/adp_logo.svg',
    type: 'HumanResources',
  },
  {
    id: 'chargebee_product_catalog_1',
    name: 'Chargebee Product Catalog 1.0',
    iconPath:
      '/integrations/coil_connectors/resources/chargebee_product_catalog_1/resources/chargebee_product_catalog_1.svg',
    type: 'Finance',
  },
  {
    id: 'kinesis',
    name: 'Amazon Kinesis Firehose',
    iconPath: '/integrations/kinesis/resources/kinesis.svg',
    type: 'File',
  },
  {
    id: 'gorgias',
    name: 'Gorgias',
    iconPath: '/integrations/coil_connectors/resources/gorgias/resources/gorgias.svg',
    type: 'Support',
  },
  {
    id: 'close',
    name: 'Close',
    iconPath: '/integrations/coil_connectors/resources/close/resources/close.svg',
    type: 'Sales',
  },
  {
    id: 'mysql_azure',
    name: 'Azure MySQL',
    iconPath: '/integrations/mysql/resources/logos/azure.png',
    type: 'Database',
  },
  {
    id: 'oracle_fusion_cloud_apps_hcm',
    name: 'Oracle Fusion Cloud Applications - HCM',
    iconPath: '/integrations/oracle_fusion_cloud_apps/resources/oracle.svg',
    type: 'HumanResources',
  },
  {
    id: 'firebase',
    name: 'Firebase',
    iconPath: '/integrations/firebase/resources/firebase.svg',
    type: 'Database',
  },
  {
    id: 'google_analytics_360',
    name: 'Google Analytics 360',
    iconPath: '/integrations/google_analytics_360/resources/google-analytics-360.svg',
    type: 'Marketing',
  },
  {
    id: 'drift',
    name: 'Drift',
    iconPath: '/integrations/coil_connectors/resources/drift/resources/drift.svg',
    type: 'Productivity',
  },
  {
    id: 'talkdesk',
    name: 'Talkdesk',
    iconPath: '/integrations/coil_connectors/resources/talkdesk/resources/talkdesk.svg',
    type: 'Support',
  },
  {
    id: 'adobe_analytics_data_feed',
    name: 'Adobe Analytics Data Feed',
    iconPath: '/integrations/adobe_analytics_data_feed/resources/adobe-analytics-data-feed-symbol.svg',
    type: 'Marketing',
  },
  {
    id: 'microsoft_dynamics_365_fo',
    name: 'Microsoft Dynamics 365 F/O ADLS',
    iconPath: '/integrations/dynamics_365_fo/resources/logo.svg',
    type: 'Sales',
  },
  {
    id: 'eventbrite',
    name: 'Eventbrite',
    iconPath: '/integrations/coil_connectors/resources/eventbrite/resources/eventbrite.svg',
    type: 'Productivity',
  },
  {
    id: 'google_search_ads_360',
    name: 'Google Search Ads 360',
    iconPath: '/integrations/google_search_ads_360/resources/google_search_ads_360.png',
    type: 'Marketing',
  },
  {
    id: 'helpscout',
    name: 'Help Scout',
    iconPath: '/integrations/helpscout/resources/helpscout.svg',
    type: 'Support',
  },
  {
    id: 'snowplow',
    name: 'Snowplow',
    iconPath: '/integrations/snowplow/resources/snowplow.svg',
    type: 'Events',
  },
  {
    id: 'sprout',
    name: 'Sprout Social',
    iconPath: '/integrations/coil_connectors/resources/sprout/resources/sprout.svg',
    type: 'Marketing',
  },
  {
    id: 'chartmogul',
    name: 'ChartMogul',
    iconPath: '/integrations/coil_connectors/resources/chartmogul/resources/chartmogul.svg',
    type: 'Marketing',
  },
  {
    id: 'documentdb',
    name: 'Amazon DocumentDB',
    iconPath: '/integrations/document/resources/documentDB.svg',
    type: 'Database',
  },
  {
    id: 'lattice',
    name: 'Lattice',
    iconPath: '/integrations/coil_connectors/resources/lattice/resources/lattice.svg',
    type: 'HumanResources',
  },
  {
    id: 'lob',
    name: 'Lob',
    iconPath: '/integrations/coil_connectors/resources/lob/resources/lob.svg',
    type: 'Marketing',
  },
  {
    id: 'the_trade_desk',
    name: 'The Trade Desk',
    iconPath: '/integrations/the_trade_desk/resources/the-trade-desk-logo-vector.svg',
    type: 'Marketing',
  },
  {
    id: 'workday_financial_management',
    name: 'Workday Financial Management',
    iconPath: '/integrations/workday_financial_management/resources/workday.png',
    type: 'Finance',
  },
  {
    id: 'oracle_sap_hva_netweaver',
    name: 'High-Volume Agent SAP ECC on Oracle with NetWeaver',
    iconPath: '/integrations/oracle/resources/oracle-sap.svg',
    type: 'Database',
  },
  {
    id: 'wrike',
    name: 'Wrike',
    iconPath: '/integrations/coil_connectors/resources/wrike/resources/wrike.svg',
    type: 'Productivity',
  },
  {
    id: 'customerio',
    name: 'Customer.io',
    iconPath: '/integrations/coil_connectors/resources/customerio/resources/customerio.svg',
    type: 'Marketing',
  },
  {
    id: 'freshservice',
    name: 'Freshservice',
    iconPath: '/integrations/freshservice/resources/freshservice.svg',
    type: 'Engineering',
  },
  {
    id: 'productboard',
    name: 'Productboard',
    iconPath: '/integrations/coil_connectors/resources/productboard/resources/productboard.svg',
    type: 'Engineering',
  },
  {
    id: 'yahoo_dsp',
    name: 'Yahoo DSP',
    iconPath: '/integrations/yahoo_dsp/resources/logo-svg.svg',
    type: 'Marketing',
  },
  {
    id: 'business_central',
    name: 'Microsoft Dynamics 365 Business Central',
    iconPath: '/integrations/business_central/resources/business_central.svg',
    type: 'Finance',
  },
  {
    id: 'apache_kafka',
    name: 'Apache Kafka',
    iconPath: '/integrations/kafka/resources/apache_kafka.svg',
    type: 'Events',
  },
  {
    id: 'fullstory',
    name: 'FullStory',
    iconPath: '/integrations/coil_connectors/resources/fullstory/resources/fullstory.svg',
    type: 'Productivity',
  },
  {
    id: 'gocardless',
    name: 'GoCardless',
    iconPath: '/integrations/coil_connectors/resources/gocardless/resources/gocardless_logo.svg',
    type: 'Finance',
  },
  {
    id: 'lever',
    name: 'Lever',
    iconPath: '/integrations/lever/resources/lever.svg',
    type: 'HumanResources',
  },
  {
    id: 'oracle_ebs',
    name: 'Oracle EBS',
    iconPath: '/integrations/oracle/resources/oracle.svg',
    type: 'Database',
  },
  {
    id: 'shipstation',
    name: 'ShipStation',
    iconPath: '/integrations/coil_connectors/resources/shipstation/resources/shipstation.svg',
    type: 'Productivity',
  },
  {
    id: 'splitio',
    name: 'Split',
    iconPath: '/integrations/coil_connectors/resources/splitio/resources/splitio.svg',
    type: 'Engineering',
  },
  {
    id: 'mavenlink',
    name: 'Mavenlink',
    iconPath: '/integrations/mavenlink/resources/mavenlink.svg',
    type: 'Productivity',
  },
  {
    id: 'medallia',
    name: 'Medallia',
    iconPath: '/integrations/medallia/resources/medallia.svg',
    type: 'Support',
  },
  {
    id: 'db2i_sap_hva',
    name: 'High-Volume Agent SAP ECC on Db2 for i',
    iconPath: '/integrations/db2i_hva/resources/icon-db2i-sap.svg',
    type: 'Database',
  },
  {
    id: 'light_speed_retail',
    name: 'Lightspeed Retail',
    iconPath: '/integrations/lightspeed_retail/resources/lightspeed.svg',
    type: 'Finance',
  },
  {
    id: 'sql_server_sap_ecc_hva',
    name: 'High-Volume Agent SAP ECC on SQL Server',
    iconPath: '/integrations/sql_server_hva/resources/sql_server_sap_ecc_hva.svg',
    type: 'Database',
  },
  {
    id: 'assembled',
    name: 'Assembled',
    iconPath: '/integrations/coil_connectors/resources/assembled/resources/assembled.svg',
    type: 'Support',
  },
  {
    id: 'brex',
    name: 'Brex',
    iconPath: '/integrations/coil_connectors/resources/brex/resources/brex.svg',
    type: 'Finance',
  },
  {
    id: 'clari',
    name: 'Clari',
    iconPath: '/integrations/coil_connectors/resources/clari/resources/clari.svg',
    type: 'Marketing',
  },
  {
    id: 'concur',
    name: 'SAP Concur',
    iconPath: '/integrations/sap_concur/resources/sap_concur_logo.svg',
    type: 'Finance',
  },
  {
    id: 'easypost',
    name: 'EasyPost',
    iconPath: '/integrations/coil_connectors/resources/easypost/resources/easypost.svg',
    type: 'Finance',
  },
  {
    id: 'heap',
    name: 'Heap',
    iconPath: '/integrations/heap/resources/heap.svg',
    type: 'Events',
  },
  {
    id: 'hibob',
    name: 'HiBob',
    iconPath: '/integrations/coil_connectors/resources/hibob/resources/hibob.svg',
    type: 'HumanResources',
  },
  {
    id: 'maria_azure',
    name: 'Azure MariaDB',
    iconPath: '/integrations/mysql/resources/logos/maria_azure.png',
    type: 'Database',
  },
  {
    id: 'sailthru',
    name: 'Sailthru',
    iconPath: '/integrations/sailthru/resources/sailthru.svg',
    type: 'Marketing',
  },
  {
    id: '6sense',
    name: '6sense',
    iconPath: '/integrations/coil_connectors/resources/6sense/resources/6sense.svg',
    type: 'Engineering',
  },
  {
    id: 'chameleon',
    name: 'Chameleon',
    iconPath: '/integrations/coil_connectors/resources/chameleon/resources/chameleon.svg',
    type: 'Engineering',
  },
  {
    id: 'gladly',
    name: 'Gladly',
    iconPath: '/integrations/coil_connectors/resources/gladly/resources/gladly.svg',
    type: 'Productivity',
  },
  {
    id: 'hana_sap_hva_s4_netweaver',
    name: 'HVA SAP S/4HANA with NetWeaver',
    iconPath: '/integrations/hana_sap_hva/resources/hana_sap.jpeg',
    type: 'Database',
  },
  {
    id: 'dear',
    name: 'DEAR',
    iconPath: '/integrations/coil_connectors/resources/dear/resources/dear.svg',
    type: 'Marketing',
  },
  {
    id: 'billing_platform',
    name: 'BillingPlatform',
    iconPath: '/integrations/coil_connectors/resources/billing_platform/resources/billing_platform.svg',
    type: 'Finance',
  },
  {
    id: 'chorusai',
    name: 'Chorus',
    iconPath: '/integrations/coil_connectors/resources/chorusai/resources/chorusai.svg',
    type: 'Marketing',
  },
  {
    id: 'magento_mysql',
    name: 'Magento MySQL',
    iconPath: '/integrations/mysql/resources/logos/magento.png',
    type: 'Database',
  },
  {
    id: 'mailgun',
    name: 'Mailgun',
    iconPath: '/integrations/coil_connectors/resources/mailgun/resources/mailgun.svg',
    type: 'Marketing',
  },
  {
    id: 'opendistro',
    name: 'Open Distro',
    iconPath: '/integrations/elasticsearch/resources/logos/opendistro.png',
    type: 'Database',
  },
  {
    id: 'samsara',
    name: 'Samsara',
    iconPath: '/integrations/coil_connectors/resources/samsara/resources/samsara.svg',
    type: 'Productivity',
  },
  {
    id: 'elastic_cloud',
    name: 'Elastic Cloud',
    iconPath: '/integrations/elasticsearch/resources/logos/elastic_cloud.png',
    type: 'Database',
  },
  {
    id: 'posthog',
    name: 'PostHog',
    iconPath: '/integrations/coil_connectors/resources/posthog/resources/posthog.svg',
    type: 'Engineering',
  },
  {
    id: 'channel_advisor',
    name: 'ChannelAdvisor',
    iconPath: '/integrations/coil_connectors/resources/channel_advisor/resources/channel_advisor.svg',
    type: 'Finance',
  },
  {
    id: 'adroll',
    name: 'AdRoll',
    iconPath: '/integrations/adroll/resources/adroll.svg',
    type: 'Marketing',
  },
  {
    id: '360learning',
    name: '360Learning',
    iconPath: '/integrations/coil_connectors/resources/360learning/resources/360learning.svg',
    type: 'HumanResources',
  },
  {
    id: 'aws_cost',
    name: 'AWS Cost Explorer',
    iconPath: '/integrations/aws_cost/resources/aws_cost.svg',
    type: 'Finance',
  },
  {
    id: 'braintree_sandbox',
    name: 'Braintree Sandbox',
    iconPath: '/integrations/braintree/resources/braintree.svg',
    type: 'Finance',
  },
  {
    id: 'circleci',
    name: 'CircleCI',
    iconPath: '/integrations/coil_connectors/resources/circleci/resources/circleci.svg',
    type: 'Engineering',
  },
  {
    id: 'cockroachdb',
    name: 'CockroachDB',
    iconPath: '/integrations/cockroachdb/resources/cockroachdb.svg',
    type: 'Database',
  },
  {
    id: 'crossbeam',
    name: 'Crossbeam',
    iconPath: '/integrations/coil_connectors/resources/crossbeam/resources/crossbeam.svg',
    type: 'Sales',
  },
  {
    id: 'friendbuy',
    name: 'Friendbuy',
    iconPath: '/integrations/coil_connectors/resources/friendbuy/resources/friendbuy.svg',
    type: 'Productivity',
  },
  {
    id: 'incidentio',
    name: 'Incident.io',
    iconPath: '/integrations/coil_connectors/resources/incidentio/resources/incidentio.svg',
    type: 'Productivity',
  },
  {
    id: 'livechat',
    name: 'LiveChat',
    iconPath: '/integrations/coil_connectors/resources/livechat/resources/livechat.svg',
    type: 'Productivity',
  },
  {
    id: 'optimizely',
    name: 'Optimizely',
    iconPath: '/integrations/optimizely/resources/optimizely.svg',
    type: 'Events',
  },
  {
    id: 'qualaroo',
    name: 'Qualaroo',
    iconPath: '/integrations/coil_connectors/resources/qualaroo/resources/qualaroo.svg',
    type: 'Support',
  },
  {
    id: 'statsig',
    name: 'Statsig',
    iconPath: '/integrations/coil_connectors/resources/statsig/resources/statsig.svg',
    type: 'Engineering',
  },
  {
    id: 'tempo',
    name: 'Tempo',
    iconPath: '/integrations/coil_connectors/resources/tempo/resources/tempo.svg',
    type: 'Engineering',
  },
  {
    id: 'buildkite',
    name: 'Buildkite',
    iconPath: '/integrations/coil_connectors/resources/buildkite/resources/buildkite.svg',
    type: 'Engineering',
  },
  {
    id: 'es_self_hosted',
    name: 'Self Hosted Elasticsearch',
    iconPath: '/integrations/elasticsearch/resources/logos/elastic.png',
    type: 'Database',
  },
  {
    id: 'open_data_dc',
    name: 'Open Data DC',
    iconPath: '/integrations/coil_connectors/resources/open_data_dc/resources/open_data_dc.svg',
    type: 'Productivity',
  },
  {
    id: 'sap_business_by_design',
    name: 'SAP Business ByDesign',
    iconPath: '/integrations/sap_business_bydesign/resources/logo.png',
    type: 'Finance',
  },
  {
    id: 'commercetools',
    name: 'commercetools',
    iconPath: '/integrations/coil_connectors/resources/commercetools/resources/commercetools.svg',
    type: 'Marketing',
  },
  {
    id: 'aws_inventory',
    name: 'AWS Inventory',
    iconPath: '/integrations/aws_inventory/resources/aws_inventory.svg',
    type: 'API',
  },
  {
    id: 'core_benchmarking',
    name: 'Core Benchmarking',
    iconPath: '/integrations/core_benchmarking/resources/core_benchmarking.svg',
    type: 'Engineering',
  },
  {
    id: 'docebo',
    name: 'Docebo',
    iconPath: '/integrations/coil_connectors/resources/docebo/resources/docebo.svg',
    type: 'HumanResources',
  },
  {
    id: 'flexport',
    name: 'Flexport',
    iconPath: '/integrations/coil_connectors/resources/flexport/resources/flexport.svg',
    type: 'Productivity',
  },
  {
    id: 'grepsr',
    name: 'Grepsr',
    iconPath: '/integrations/coil_connectors/resources/grepsr/resources/grepsr.svg',
    type: 'Engineering',
  },
  {
    id: 'grin',
    name: 'Grin',
    iconPath: '/integrations/coil_connectors/resources/grin/resources/grin.svg',
    type: 'Marketing',
  },
  {
    id: 'ironclad',
    name: 'Ironclad',
    iconPath: '/integrations/coil_connectors/resources/ironclad/resources/ironclad.svg',
    type: 'Productivity',
  },
  {
    id: 'mandrill',
    name: 'Mandrill',
    iconPath: '/integrations/mandrill/resources/mandrill.svg',
    type: 'Marketing',
  },
  {
    id: 'mux',
    name: 'Mux',
    iconPath: '/integrations/coil_connectors/resources/mux/resources/mux.svg',
    type: 'Marketing',
  },
  {
    id: 'notion',
    name: 'Notion',
    iconPath: '/integrations/coil_connectors/resources/notion/resources/notion.svg',
    type: 'Engineering',
  },
  {
    id: 'opensearch',
    name: 'Opensearch',
    iconPath: '/integrations/elasticsearch/resources/logos/opensearch.png',
    type: 'Database',
  },
  {
    id: 'oracle_sap_hva',
    name: 'High-Volume Agent SAP ECC on Oracle',
    iconPath: '/integrations/oracle/resources/oracle-sap.svg',
    type: 'Database',
  },
  {
    id: 'sentryio',
    name: 'Sentryio',
    iconPath: '/integrations/coil_connectors/resources/sentryio/resources/sentryio.svg',
    type: 'Engineering',
  },
  {
    id: 'skuvault',
    name: 'SkuVault',
    iconPath: '/integrations/coil_connectors/resources/skuvault/resources/skuvault.svg',
    type: 'Productivity',
  },
  {
    id: 'when_i_work',
    name: 'When I Work',
    iconPath: '/integrations/coil_connectors/resources/when_i_work/resources/when_i_work.svg',
    type: 'HumanResources',
  },
  {
    id: 'azure_event_hub',
    name: 'Azure Event Hubs',
    iconPath: '/integrations/kafka/resources/event_hub.svg',
    type: 'Events',
  },
  {
    id: 'freshchat',
    name: 'Freshchat',
    iconPath: '/integrations/coil_connectors/resources/freshchat/resources/freshchat.svg',
    type: 'Support',
  },
  {
    id: 'genesys',
    name: 'Genesys',
    iconPath: '/integrations/coil_connectors/resources/genesys/resources/genesys.svg',
    type: 'Productivity',
  },
  {
    id: 'hana_sap_hva_ecc_netweaver',
    name: 'HVA SAP ECC on HANA DB with NetWeaver',
    iconPath: '/integrations/hana_sap_hva/resources/hana_sap.jpeg',
    type: 'Database',
  },
  {
    id: 'height',
    name: 'Height',
    iconPath: '/integrations/height/resources/height.svg',
    type: 'Productivity',
  },
  {
    id: 'paypal_sandbox',
    name: 'Paypal Sandbox',
    iconPath: '/integrations/paypal/resources/paypal.svg',
    type: 'Finance',
  },
  {
    id: 'planetscale',
    name: 'PlanetScale',
    iconPath: '/integrations/sdk_connectors/resources/fivetran_sdk.svg',
    type: 'Database',
  },
  {
    id: 'gitlab',
    name: 'GitLab',
    iconPath: '/integrations/coil_connectors/resources/gitlab/resources/gitlab.svg',
    type: 'Engineering',
  },
  {
    id: 'khoros_care',
    name: 'Khoros Care',
    iconPath: '/integrations/coil_connectors/resources/khoros_care/resources/khoros_care.svg',
    type: 'Support',
  },
  {
    id: 'confluence',
    name: 'Confluence',
    iconPath: '/integrations/coil_connectors/resources/confluence/resources/confluence.svg',
    type: 'Engineering',
  },
  {
    id: 'azure_service_bus',
    name: 'Azure Service Bus',
    iconPath: '/integrations/azure_service_bus/resources/azure_service_bus.svg',
    type: 'Events',
  },
  {
    id: 'hana_sap_hva_ecc',
    name: 'HVA SAP ECC on HANA DB',
    iconPath: '/integrations/hana_sap_hva/resources/hana_sap.jpeg',
    type: 'Database',
  },
  {
    id: 'yotpo',
    name: 'Yotpo',
    iconPath: '/integrations/coil_connectors/resources/yotpo/resources/yotpo.svg',
    type: 'Marketing',
  },
  {
    id: 'adjust',
    name: 'Adjust',
    iconPath: '/integrations/adjust/resources/adjust-logo.svg',
    type: 'Marketing',
  },
  {
    id: 'algolia',
    name: 'Algolia',
    iconPath: '/integrations/coil_connectors/resources/algolia/resources/algolia.svg',
    type: 'Productivity',
  },
  {
    id: 'aws_msk',
    name: 'AWS MSK',
    iconPath: '/integrations/kafka/resources/aws_msk.svg',
    type: 'Events',
  },
  {
    id: 'birdeye',
    name: 'Birdeye',
    iconPath: '/integrations/coil_connectors/resources/birdeye/resources/birdeye.svg',
    type: 'Support',
  },
  {
    id: 'campaignmonitor',
    name: 'Campaign Monitor',
    iconPath: '/integrations/coil_connectors/resources/campaignmonitor/resources/campaignmonitor.svg',
    type: 'Marketing',
  },
  {
    id: 'deputy',
    name: 'Deputy',
    iconPath: '/integrations/coil_connectors/resources/deputy/resources/deputy.svg',
    type: 'HumanResources',
  },
  {
    id: 'dixa',
    name: 'Dixa',
    iconPath: '/integrations/coil_connectors/resources/dixa/resources/dixa.svg',
    type: 'Productivity',
  },
  {
    id: 'drip',
    name: 'Drip',
    iconPath: '/integrations/coil_connectors/resources/drip/resources/drip.svg',
    type: 'Marketing',
  },
  {
    id: 'infobip',
    name: 'Infobip',
    iconPath: '/integrations/coil_connectors/resources/infobip/resources/infobip.svg',
    type: 'Productivity',
  },
  {
    id: 'integral_ad_science',
    name: 'Integral AD Science',
    iconPath: '/integrations/coil_connectors/resources/integral_ad_science/resources/integral_ad_science.svg',
    type: 'Marketing',
  },
  {
    id: 'kissmetrics',
    name: 'Kissmetrics',
    iconPath: '/integrations/coil_connectors/resources/kissmetrics/resources/kissmetrics.svg',
    type: 'Marketing',
  },
  {
    id: 'moengage',
    name: 'Moengage',
    iconPath: '/integrations/coil_connectors/resources/moengage/resources/moengage.svg',
    type: 'Productivity',
  },
  {
    id: 'revops',
    name: 'RevOps',
    iconPath: '/integrations/coil_connectors/resources/revops/resources/revops.svg',
    type: 'Sales',
  },
  {
    id: 'rootly',
    name: 'Rootly',
    iconPath: '/integrations/coil_connectors/resources/rootly/resources/rootly.svg',
    type: 'Engineering',
  },
  {
    id: 'shopware',
    name: 'Shopware',
    iconPath: '/integrations/coil_connectors/resources/shopware/resources/shopware.svg',
    type: 'Marketing',
  },
  {
    id: 'stylight',
    name: 'Stylight',
    iconPath: '/integrations/coil_connectors/resources/stylight/resources/stylight.svg',
    type: 'Productivity',
  },
  {
    id: 'survicate',
    name: 'Survicate',
    iconPath: '/integrations/coil_connectors/resources/survicate/resources/survicate.svg',
    type: 'Support',
  },
  {
    id: 'trello',
    name: 'Trello',
    iconPath: '/integrations/coil_connectors/resources/trello/resources/trello.svg',
    type: 'Engineering',
  },
  {
    id: 'trimble',
    name: 'Trimble',
    iconPath: '/integrations/coil_connectors/resources/trimble/resources/trimble.svg',
    type: 'Sales',
  },
  {
    id: 'wordpress',
    name: 'WordPress',
    iconPath: '/integrations/coil_connectors/resources/wordpress/resources/wordpress.svg',
    type: 'Productivity',
  },
  {
    id: 'workable',
    name: 'Workable',
    iconPath: '/integrations/coil_connectors/resources/workable/resources/workable.svg',
    type: 'HumanResources',
  },
  {
    id: 'chargedesk',
    name: 'ChargeDesk',
    iconPath: '/integrations/coil_connectors/resources/chargedesk/resources/chargedesk.svg',
    type: 'Marketing',
  },
  {
    id: 'employment_hero',
    name: 'Employment Hero',
    iconPath: '/integrations/coil_connectors/resources/employment_hero/resources/employment_hero.svg',
    type: 'HumanResources',
  },
  {
    id: 'orbit',
    name: 'Orbit',
    iconPath: '/integrations/coil_connectors/resources/orbit/resources/orbit.svg',
    type: 'Marketing',
  },
  {
    id: 'splunk',
    name: 'Splunk',
    iconPath: '/integrations/splunk/resources/splunk.svg',
    type: 'Engineering',
  },
  {
    id: 'zoom',
    name: 'Zoom',
    iconPath: '/integrations/coil_connectors/resources/zoom/resources/zoom.svg',
    type: 'Productivity',
  },
  {
    id: 'replyio',
    name: 'Reply.io',
    iconPath: '/integrations/coil_connectors/resources/replyio/resources/replyio.svg',
    type: 'Marketing',
  },
  {
    id: '15five',
    name: '15five',
    iconPath: '/integrations/coil_connectors/resources/15five/resources/15five.svg',
    type: 'HumanResources',
  },
  {
    id: 'acumatica',
    name: 'Acumatica',
    iconPath: '/integrations/coil_connectors/resources/acumatica/resources/acumatica.svg',
    type: 'Marketing',
  },
  {
    id: 'ada',
    name: 'Ada',
    iconPath: '/integrations/coil_connectors/resources/ada/resources/ada.svg',
    type: 'Support',
  },
  {
    id: 'ask_nicely',
    name: 'Ask Nicely',
    iconPath: '/integrations/coil_connectors/resources/ask_nicely/resources/ask_nicely.svg',
    type: 'Marketing',
  },
  {
    id: 'atlassian_jira_align',
    name: 'Atlassian Jira Align',
    iconPath: '/integrations/coil_connectors/resources/atlassian_jira_align/resources/atlassian_jira_align.svg',
    type: 'Marketing',
  },
  {
    id: 'atlassian_ops_genie',
    name: 'Atlassian Ops Genie',
    iconPath: '/integrations/coil_connectors/resources/atlassian_ops_genie/resources/atlassian_ops_genie.svg',
    type: 'Marketing',
  },
  {
    id: 'attio',
    name: 'Attio',
    iconPath: '/integrations/coil_connectors/resources/attio/resources/attio.svg',
    type: 'Marketing',
  },
  {
    id: 'awin',
    name: 'Awin',
    iconPath: '/integrations/coil_connectors/resources/awin/resources/awin.svg',
    type: 'Marketing',
  },
  {
    id: 'aws_cloudtrail',
    name: 'AWS CloudTrail',
    iconPath: '/integrations/aws_cloudtrail/resources/aws_cloudtrail.svg',
    type: 'File',
  },
  {
    id: 'ballotready',
    name: 'BallotReady',
    iconPath: '/integrations/coil_connectors/resources/ballotready/resources/ballotready.svg',
    type: 'Marketing',
  },
  {
    id: 'canny',
    name: 'Canny',
    iconPath: '/integrations/coil_connectors/resources/canny/resources/canny.svg',
    type: 'Engineering',
  },
  {
    id: 'ceridian_dayforce',
    name: 'Ceridian Dayforce',
    iconPath: '/integrations/coil_connectors/resources/ceridian_dayforce/resources/ceridian_dayforce.svg',
    type: 'HumanResources',
  },
  {
    id: 'chargify',
    name: 'Chargify',
    iconPath: '/integrations/coil_connectors/resources/chargify/resources/chargify.svg',
    type: 'Engineering',
  },
  {
    id: 'churnkey',
    name: 'Churnkey',
    iconPath: '/integrations/coil_connectors/resources/churnkey/resources/churnkey.svg',
    type: 'Marketing',
  },
  {
    id: 'churnzero',
    name: 'Churnzero',
    iconPath: '/integrations/coil_connectors/resources/churnzero/resources/churnzero.svg',
    type: 'Productivity',
  },
  {
    id: 'cloudfront',
    name: 'Amazon CloudFront',
    iconPath: '/integrations/cloudfront/resources/cloudfront.svg',
    type: 'File',
  },
  {
    id: 'codefresh',
    name: 'Codefresh',
    iconPath: '/integrations/coil_connectors/resources/codefresh/resources/codefresh.svg',
    type: 'Engineering',
  },
  {
    id: 'datadog',
    name: 'Datadog',
    iconPath: '/integrations/coil_connectors/resources/datadog/resources/datadog.svg',
    type: 'Engineering',
  },
  {
    id: 'dcl_corp',
    name: 'DCL Corp',
    iconPath: '/integrations/coil_connectors/resources/dcl_corp/resources/dcl_corp.svg',
    type: 'Productivity',
  },
  {
    id: 'destini',
    name: 'Destini',
    iconPath: '/integrations/coil_connectors/resources/destini/resources/destini.svg',
    type: 'Marketing',
  },
  {
    id: 'drata',
    name: 'Drata',
    iconPath: '/integrations/coil_connectors/resources/drata/resources/drata.svg',
    type: 'Productivity',
  },
  {
    id: 'dropbox_sign',
    name: 'Dropbox Sign',
    iconPath: '/integrations/coil_connectors/resources/dropbox_sign/resources/dropbox_sign_logo.svg',
    type: 'Productivity',
  },
  {
    id: 'everhour',
    name: 'Everhour',
    iconPath: '/integrations/coil_connectors/resources/everhour/resources/everhour.svg',
    type: 'Productivity',
  },
  {
    id: 'freshbooks',
    name: 'FreshBooks',
    iconPath: '/integrations/coil_connectors/resources/freshbooks/resources/freshbooks.svg',
    type: 'Finance',
  },
  {
    id: 'gem',
    name: 'Gem',
    iconPath: '/integrations/coil_connectors/resources/gem/resources/gem.svg',
    type: 'HumanResources',
  },
  {
    id: 'guru',
    name: 'Guru',
    iconPath: '/integrations/coil_connectors/resources/guru/resources/guru.svg',
    type: 'Engineering',
  },
  {
    id: 'helpshift',
    name: 'Helpshift',
    iconPath: '/integrations/coil_connectors/resources/helpshift/resources/helpshift.svg',
    type: 'Support',
  },
  {
    id: 'insightly',
    name: 'Insightly',
    iconPath: '/integrations/coil_connectors/resources/insightly/resources/insightly.svg',
    type: 'Productivity',
  },
  {
    id: 'ivanti',
    name: 'Ivanti',
    iconPath: '/integrations/coil_connectors/resources/ivanti/resources/ivanti.svg',
    type: 'Productivity',
  },
  {
    id: 'kandji',
    name: 'Kandji',
    iconPath: '/integrations/coil_connectors/resources/kandji/resources/kandji.svg',
    type: 'Engineering',
  },
  {
    id: 'learnupon',
    name: 'Learnupon',
    iconPath: '/integrations/coil_connectors/resources/learnupon/resources/learnupon.svg',
    type: 'Engineering',
  },
  {
    id: 'lessonly',
    name: 'Lessonly',
    iconPath: '/integrations/coil_connectors/resources/lessonly/resources/lessonly.svg',
    type: 'HumanResources',
  },
  {
    id: 'linksquares',
    name: 'LinkSquares',
    iconPath: '/integrations/coil_connectors/resources/linksquares/resources/linksquares.svg',
    type: 'Productivity',
  },
  {
    id: 'loopio',
    name: 'Loopio',
    iconPath: '/integrations/coil_connectors/resources/loopio/resources/loopio.svg',
    type: 'Productivity',
  },
  {
    id: 'luma',
    name: 'Luma',
    iconPath: '/integrations/coil_connectors/resources/luma/resources/luma.svg',
    type: 'Productivity',
  },
  {
    id: 'maestro_qa',
    name: 'MaestroQA',
    iconPath: '/integrations/coil_connectors/resources/maestro_qa/resources/maestro_qa.svg',
    type: 'Productivity',
  },
  {
    id: 'magento_mysql_rds',
    name: 'Magento MySQL RDS',
    iconPath: '/integrations/mysql/resources/logos/magento.png',
    type: 'Database',
  },
  {
    id: 'mixmax',
    name: 'Mixmax',
    iconPath: '/integrations/coil_connectors/resources/mixmax/resources/mixmax.svg',
    type: 'Productivity',
  },
  {
    id: 'mountain',
    name: 'Mountain',
    iconPath: '/integrations/coil_connectors/resources/mountain/resources/mountain.svg',
    type: 'Marketing',
  },
  {
    id: 'namely',
    name: 'Namely',
    iconPath: '/integrations/coil_connectors/resources/namely/resources/namely.svg',
    type: 'HumanResources',
  },
  {
    id: 'ortto',
    name: 'Ortto',
    iconPath: '/integrations/coil_connectors/resources/ortto/resources/ortto.svg',
    type: 'Marketing',
  },
  {
    id: 'papershift',
    name: 'Papershift',
    iconPath: '/integrations/coil_connectors/resources/papershift/resources/papershift.svg',
    type: 'HumanResources',
  },
  {
    id: 'pinpoint',
    name: 'Pinpoint',
    iconPath: '/integrations/coil_connectors/resources/pinpoint/resources/pinpoint.svg',
    type: 'HumanResources',
  },
  {
    id: 'rarible',
    name: 'Rarible',
    iconPath: '/integrations/coil_connectors/resources/rarible/resources/rarible.svg',
    type: 'Engineering',
  },
  {
    id: 'redshift_db',
    name: 'Redshift',
    iconPath: '/integrations/redshift/resources/redshift.png',
    type: 'Database',
  },
  {
    id: 'rollbar',
    name: 'Rollbar',
    iconPath: '/integrations/coil_connectors/resources/rollbar/resources/rollbar.svg',
    type: 'Engineering',
  },
  {
    id: 'rtb_house',
    name: 'RTB House',
    iconPath: '/integrations/coil_connectors/resources/rtb_house/resources/rtb_house.svg',
    type: 'Marketing',
  },
  {
    id: 'sage_hr',
    name: 'Sage HR',
    iconPath: '/integrations/coil_connectors/resources/sage_hr/resources/sage_hr.svg',
    type: 'Database',
  },
  {
    id: 'scorm',
    name: 'SCORM',
    iconPath: '/integrations/coil_connectors/resources/scorm/resources/scorm.svg',
    type: 'HumanResources',
  },
  {
    id: 'sendinblue',
    name: 'Sendinblue',
    iconPath: '/integrations/coil_connectors/resources/sendinblue/resources/sendinblue.svg',
    type: 'Marketing',
  },
  {
    id: 'sensor_tower',
    name: 'Sensor Tower',
    iconPath: '/integrations/coil_connectors/resources/sensor_tower/resources/sensor_tower.svg',
    type: 'Marketing',
  },
  {
    id: 'skilljar',
    name: 'Skilljar',
    iconPath: '/integrations/coil_connectors/resources/skilljar/resources/skilljar.svg',
    type: 'Marketing',
  },
  {
    id: 'smadex',
    name: 'Smadex',
    iconPath: '/integrations/coil_connectors/resources/smadex/resources/smadex.svg',
    type: 'Marketing',
  },
  {
    id: 'squarespace',
    name: 'Squarespace',
    iconPath: '/integrations/coil_connectors/resources/squarespace/resources/squarespace.svg',
    type: 'Finance',
  },
  {
    id: 'stackadapt',
    name: 'Stackadapt',
    iconPath: '/integrations/coil_connectors/resources/stackadapt/resources/stackadapt.svg',
    type: 'Engineering',
  },
  {
    id: 'talkwalker',
    name: 'Talkwalker',
    iconPath: '/integrations/coil_connectors/resources/talkwalker/resources/talkwalker.svg',
    type: 'Productivity',
  },
  {
    id: 'teradata',
    name: 'Teradata',
    iconPath: '/integrations/teradata/resources/teradata.png',
    type: 'Database',
  },
  {
    id: 'toggl',
    name: 'Toggl',
    iconPath: '/integrations/coil_connectors/resources/toggl/resources/toggl.svg',
    type: 'Engineering',
  },
  {
    id: 'transcend',
    name: 'Transcend',
    iconPath: '/integrations/coil_connectors/resources/transcend/resources/transcend.svg',
    type: 'Engineering',
  },
  {
    id: 'trisolute',
    name: 'Trisolute',
    iconPath: '/integrations/coil_connectors/resources/trisolute/resources/trisolute.svg',
    type: 'Productivity',
  },
  {
    id: 'txodds',
    name: 'TXODDS',
    iconPath: '/integrations/coil_connectors/resources/txodds/resources/txodds.svg',
    type: 'Productivity',
  },
  {
    id: 'uservoice',
    name: 'UserVoice',
    iconPath: '/integrations/uservoice/resources/uservoice.svg',
    type: 'Support',
  },
  {
    id: 'vitally',
    name: 'Vitally',
    iconPath: '/integrations/coil_connectors/resources/vitally/resources/vitally.svg',
    type: 'Productivity',
  },
  {
    id: 'workramp',
    name: 'Workramp',
    iconPath: '/integrations/coil_connectors/resources/workramp/resources/workramp.svg',
    type: 'Productivity',
  },
  {
    id: 'xray',
    name: 'Xray',
    iconPath: '/integrations/coil_connectors/resources/xray/resources/xray.svg',
    type: 'Engineering',
  },
  {
    id: 'yahoo_gemini',
    name: 'Verizon Media',
    iconPath: '/integrations/yahoo_gemini/resources/verizon.png',
    type: 'Marketing',
  },
  {
    id: 'ezofficeinventory',
    name: 'Ezofficeinventory',
    iconPath: '/integrations/coil_connectors/resources/ezofficeinventory/resources/ezofficeinventory.svg',
    type: 'Engineering',
  },
  {
    id: 'launchdarkly',
    name: 'Launchdarkly',
    iconPath: '/integrations/coil_connectors/resources/launchdarkly/resources/launchdarkly.svg',
    type: 'Engineering',
  },
  {
    id: 'nylas',
    name: 'Nylas',
    iconPath: '/integrations/coil_connectors/resources/nylas/resources/nylas.svg',
    type: 'Engineering',
  },
  {
    id: 'podio',
    name: 'Podio',
    iconPath: '/integrations/coil_connectors/resources/podio/resources/podio.svg',
    type: 'Productivity',
  },
  {
    id: 'sonarqube',
    name: 'SonarQube',
    iconPath: '/integrations/coil_connectors/resources/sonarqube/resources/sonarqube.svg',
    type: 'Productivity',
  },
  {
    id: 'tableau_source',
    name: 'Tableau_Source',
    iconPath: '/integrations/coil_connectors/resources/tableau_source/resources/tableau_source.svg',
    type: 'Engineering',
  },
  {
    id: 'teamwork',
    name: 'Teamwork',
    iconPath: '/integrations/coil_connectors/resources/teamwork/resources/teamwork.svg',
    type: 'Productivity',
  },
  {
    id: 'hana_sap_hva_s4',
    name: 'HVA SAP S/4HANA',
    iconPath: '/integrations/hana_sap_hva/resources/hana_sap.jpeg',
    type: 'Database',
  },
  {
    id: 'google_cloud_sqlserver',
    name: 'Google Cloud SQL - SQL Server',
    iconPath: '/integrations/sql_server/resources/cloudsql.png',
    type: 'Database',
  },
  {
    id: 'adyen',
    name: 'Adyen',
    iconPath: '/integrations/coil_connectors/resources/adyen/resources/adyen.svg',
    type: 'Productivity',
  },
  {
    id: 'affinity',
    name: 'Affinity',
    iconPath: '/integrations/coil_connectors/resources/affinity/resources/affinity.svg',
    type: 'Marketing',
  },
  {
    id: 'ahrefs',
    name: 'Ahrefs',
    iconPath: '/integrations/coil_connectors/resources/ahrefs/resources/ahrefs.svg',
    type: 'Productivity',
  },
  {
    id: 'amazon_attribution',
    name: 'Amazon Attribution',
    iconPath: '/integrations/coil_connectors/resources/amazon_attribution/resources/amazon_attribution.svg',
    type: 'Marketing',
  },
  {
    id: 'atlassian_statuspage',
    name: 'Atlassian Statuspage',
    iconPath: '/integrations/coil_connectors/resources/atlassian_statuspage/resources/atlassian_statuspage.svg',
    type: 'Support',
  },
  {
    id: 'auth0',
    name: 'Auth0',
    iconPath: '/integrations/coil_connectors/resources/auth0/resources/auth0.svg',
    type: 'Productivity',
  },
  {
    id: 'calabrio',
    name: 'Calabrio',
    iconPath: '/integrations/coil_connectors/resources/calabrio/resources/calabrio.svg',
    type: 'Engineering',
  },
  {
    id: 'checkout',
    name: 'Checkout.com',
    iconPath: '/integrations/coil_connectors/resources/checkout/resources/checkout.svg',
    type: 'Productivity',
  },
  {
    id: 'contrast_security',
    name: 'Contrast Security',
    iconPath: '/integrations/coil_connectors/resources/contrast_security/resources/contrast_security.svg',
    type: 'Marketing',
  },
  {
    id: 'cvent',
    name: 'Cvent',
    iconPath: '/integrations/coil_connectors/resources/cvent/resources/cvent.svg',
    type: 'Productivity',
  },
  {
    id: 'device42',
    name: 'Device42',
    iconPath: '/integrations/coil_connectors/resources/device42/resources/device42.svg',
    type: 'Engineering',
  },
  {
    id: 'duoplane',
    name: 'Duoplane',
    iconPath: '/integrations/coil_connectors/resources/duoplane/resources/duoplane.svg',
    type: 'Sales',
  },
  {
    id: 'dynamics_365_fo',
    name: 'Microsoft Dynamics 365 F/O',
    iconPath: '/integrations/dynamics365/resources/logo.svg',
    type: 'Database',
  },
  {
    id: 'flowcode',
    name: 'Flowcode',
    iconPath: '/integrations/coil_connectors/resources/flowcode/resources/flowcode.svg',
    type: 'Productivity',
  },
  {
    id: 'fountain',
    name: 'Fountain',
    iconPath: '/integrations/coil_connectors/resources/fountain/resources/fountain.svg',
    type: 'HumanResources',
  },
  {
    id: 'gamesight',
    name: 'Gamesight',
    iconPath: '/integrations/coil_connectors/resources/gamesight/resources/gamesight.svg',
    type: 'Engineering',
  },
  {
    id: 'google_datastore',
    name: 'Google Cloud Datastore',
    iconPath: '/integrations/coil_connectors/resources/google_datastore/resources/google_datastore.svg',
    type: 'Database',
  },
  {
    id: 'google_tasks',
    name: 'Google Tasks',
    iconPath: '/integrations/coil_connectors/resources/google_tasks/resources/google_tasks.svg',
    type: 'Productivity',
  },
  {
    id: 'heroku_kafka',
    name: 'Heroku Kafka',
    iconPath: '/integrations/kafka/resources/heroku_kafka.svg',
    type: 'Events',
  },
  {
    id: 'ilevel',
    name: 'Ilevel',
    iconPath: '/integrations/coil_connectors/resources/ilevel/resources/ilevel.svg',
    type: 'Productivity',
  },
  {
    id: 'khorosmarketing',
    name: 'Khoros Marketing',
    iconPath: '/integrations/coil_connectors/resources/khorosmarketing/resources/khorosmarketing.svg',
    type: 'Productivity',
  },
  {
    id: 'klarna',
    name: 'Klarna',
    iconPath: '/integrations/coil_connectors/resources/klarna/resources/klarna.svg',
    type: 'Finance',
  },
  {
    id: 'linkedin_talent',
    name: 'Linkedin Talent Solutions',
    iconPath: '/integrations/coil_connectors/resources/linkedin_talent/resources/linkedin_talent.svg',
    type: 'HumanResources',
  },
  {
    id: 'looker_source',
    name: 'Looker',
    iconPath: '/integrations/coil_connectors/resources/looker_source/resources/looker_source.svg',
    type: 'Productivity',
  },
  {
    id: 'microsoft_smart_store_analytics',
    name: 'Microsoft Smart Store Analytics',
    iconPath:
      '/integrations/coil_connectors/resources/microsoft_smart_store_analytics/resources/microsoft_smart_store_analytics.svg',
    type: 'Marketing',
  },
  {
    id: 'officernd',
    name: 'Officernd',
    iconPath: '/integrations/coil_connectors/resources/officernd/resources/officernd.svg',
    type: 'Productivity',
  },
  {
    id: 'paylocity',
    name: 'Paylocity',
    iconPath: '/integrations/coil_connectors/resources/paylocity/resources/paylocity.svg',
    type: 'HumanResources',
  },
  {
    id: 'pingdom',
    name: 'Pingdom',
    iconPath: '/integrations/coil_connectors/resources/pingdom/resources/pingdom.svg',
    type: 'Engineering',
  },
  {
    id: 'power_reviews',
    name: 'PowerReviews',
    iconPath: '/integrations/coil_connectors/resources/power_reviews/resources/power_reviews.svg',
    type: 'Engineering',
  },
  {
    id: 'qgenda',
    name: 'QGenda',
    iconPath: '/integrations/coil_connectors/resources/qgenda/resources/qgenda.svg',
    type: 'Productivity',
  },
  {
    id: 'quorum',
    name: 'Quorum',
    iconPath: '/integrations/coil_connectors/resources/quorum/resources/quorum.svg',
    type: 'Engineering',
  },
  {
    id: 'rakutenadvertising',
    name: 'Rakuten Advertising',
    iconPath: '/integrations/coil_connectors/resources/rakutenadvertising/resources/rakutenadvertising.svg',
    type: 'Marketing',
  },
  {
    id: 'retailnext',
    name: 'RetailNext',
    iconPath: '/integrations/coil_connectors/resources/retailnext/resources/retailnext.svg',
    type: 'Marketing',
  },
  {
    id: 'sap_hana',
    name: 'SAP HANA',
    iconPath: '/integrations/sap_s4hana/resources/sap-hana.jpeg',
    type: 'Database',
  },
  {
    id: 'shiphero',
    name: 'Shiphero',
    iconPath: '/integrations/coil_connectors/resources/shiphero/resources/shiphero.svg',
    type: 'Productivity',
  },
  {
    id: 'shipnetwork',
    name: 'ShipNetwork',
    iconPath: '/integrations/coil_connectors/resources/shipnetwork/resources/shipnetwork.svg',
    type: 'Productivity',
  },
  {
    id: 'sprig',
    name: 'Sprig',
    iconPath: '/integrations/coil_connectors/resources/sprig/resources/sprig.svg',
    type: 'Support',
  },
  {
    id: 'subscript',
    name: 'Subscript',
    iconPath: '/integrations/coil_connectors/resources/subscript/resources/subscript.svg',
    type: 'Finance',
  },
  {
    id: 'vonage',
    name: 'Vonage',
    iconPath: '/integrations/coil_connectors/resources/vonage/resources/vonage.svg',
    type: 'Marketing',
  },
  {
    id: 'workday_strategic_sourcing',
    name: 'Workday Strategic Sourcing ',
    iconPath:
      '/integrations/coil_connectors/resources/workday_strategic_sourcing/resources/workday_strategic_sourcing.svg',
    type: 'HumanResources',
  },
  {
    id: 'yahoo_search_ads_yahoo_japan',
    name: 'Yahoo Search Ads on Yahoo Japan',
    iconPath:
      '/integrations/coil_connectors/resources/yahoo_search_ads_yahoo_japan/resources/yahoo_search_ads_yahoo_japan.png',
    type: 'Productivity',
  },
  {
    id: 'zendesk_sunshine',
    name: 'Zendesk Sunshine',
    iconPath: '/integrations/zendesk_sunshine/resources/logo-zendesk.svg',
    type: 'Support',
  },
  {
    id: 'appfigures',
    name: 'Appfigures',
    iconPath: '/integrations/coil_connectors/resources/appfigures/resources/appfigures.svg',
    type: 'Engineering',
  },
  {
    id: 'azure_devops',
    name: 'Azure DevOps',
    iconPath: '/integrations/coil_connectors/resources/azure_devops/resources/azure_devops.svg',
    type: 'Productivity',
  },
  {
    id: 'hopin',
    name: 'Hopin',
    iconPath: '/integrations/coil_connectors/resources/hopin/resources/hopin.svg',
    type: 'Productivity',
  },
  {
    id: 'ordway_labs',
    name: 'Ordway Labs',
    iconPath: '/integrations/coil_connectors/resources/ordway_labs/resources/ordway_labs.svg',
    type: 'Finance',
  },
  {
    id: 'retently',
    name: 'Retently',
    iconPath: '/integrations/coil_connectors/resources/retently/resources/retently.svg',
    type: 'Marketing',
  },
  {
    id: 'sugarcrm',
    name: 'SugarCRM',
    iconPath: '/integrations/coil_connectors/resources/sugarcrm/resources/sugarcrm.svg',
    type: 'Support',
  },
  {
    id: 'upland',
    name: 'Upland Software',
    iconPath: '/integrations/coil_connectors/resources/upland/resources/upland.svg',
    type: 'Marketing',
  },
  {
    id: 'adobe_commerce',
    name: 'Adobe Commerce',
    iconPath: '/integrations/coil_connectors/resources/adobe_commerce/resources/adobe_commerce.svg',
    type: 'Marketing',
  },
  {
    id: 'exact_online',
    name: 'Exact Online',
    iconPath: '/integrations/coil_connectors/resources/exact_online/resources/exact_online.svg',
    type: 'Finance',
  },
  {
    id: 'iceberg',
    name: 'iceberg',
    iconPath: '/integrations/iceberg/resources/iceberg-logo.svg',
    type: 'File',
  },
  {
    id: 'sap_success_factors',
    name: 'SAP SuccessFactors',
    iconPath: '/integrations/coil_connectors/resources/sap_success_factors/resources/sap_success_factors.svg',
    type: 'HumanResources',
  },
  {
    id: 'affirm',
    name: 'affirm',
    iconPath: '/integrations/coil_connectors/resources/affirm/resources/affirm.svg',
    type: 'Engineering',
  },
  {
    id: 'azure_boards',
    name: 'Azure Boards',
    iconPath: '/integrations/coil_connectors/resources/azure_boards/resources/azure_boards.svg',
    type: 'Productivity',
  },
  {
    id: 'ramp',
    name: 'Ramp',
    iconPath: '/integrations/coil_connectors/resources/ramp/resources/ramp.svg',
    type: 'Finance',
  },
  {
    id: 'zendesk_sell',
    name: 'Zendesk Sell',
    iconPath: '/integrations/zendesk_sell/resources/zendesk_sell.svg',
    type: 'Support',
  },
  {
    id: 'ukg_pro',
    name: 'UKG Pro',
    iconPath: '/integrations/coil_connectors/resources/ukg_pro/resources/ukg_pro.svg',
    type: 'HumanResources',
  },
  {
    id: 'accredible',
    name: 'Accredible',
    iconPath: '/integrations/coil_connectors/resources/accredible/resources/accredible.svg',
    type: 'Engineering',
  },
  {
    id: 'adobe_workfront',
    name: 'Adobe Workfront',
    iconPath: '/integrations/coil_connectors/resources/adobe_workfront/resources/adobe_workfront.svg',
    type: 'Productivity',
  },
  {
    id: 'affluent',
    name: 'Affluent',
    iconPath: '/integrations/coil_connectors/resources/affluent/resources/affluent.svg',
    type: 'Marketing',
  },
  {
    id: 'aha',
    name: 'Aha',
    iconPath: '/integrations/coil_connectors/resources/aha/resources/aha.svg',
    type: 'Marketing',
  },
  {
    id: 'airship',
    name: 'Airship',
    iconPath: '/integrations/coil_connectors/resources/airship/resources/airship.svg',
    type: 'Marketing',
  },
  {
    id: 'appfollow',
    name: 'AppFollow',
    iconPath: '/integrations/coil_connectors/resources/appfollow/resources/appfollow.svg',
    type: 'Productivity',
  },
  {
    id: 'avalara',
    name: 'Avalara',
    iconPath: '/integrations/coil_connectors/resources/avalara/resources/avalara.svg',
    type: 'Sales',
  },
  {
    id: 'bill',
    name: 'Bill',
    iconPath: '/integrations/coil_connectors/resources/bill/resources/bill.svg',
    type: 'Productivity',
  },
  {
    id: 'brandwatch',
    name: 'Brandwatch',
    iconPath: '/integrations/coil_connectors/resources/brandwatch/resources/brandwatch.svg',
    type: 'Engineering',
  },
  {
    id: 'bullhorn',
    name: 'Bullhorn',
    iconPath: '/integrations/coil_connectors/resources/bullhorn/resources/bullhorn.svg',
    type: 'Engineering',
  },
  {
    id: 'calltrackingmetrics',
    name: 'Call Tracking Metrics',
    iconPath: '/integrations/coil_connectors/resources/calltrackingmetrics/resources/calltrackingmetrics.svg',
    type: 'Productivity',
  },
  {
    id: 'callrail',
    name: 'CallRail',
    iconPath: '/integrations/coil_connectors/resources/callrail/resources/callrail.svg',
    type: 'Productivity',
  },
  {
    id: 'canvas_medical',
    name: 'Canvas Medical',
    iconPath: '/integrations/coil_connectors/resources/canvas_medical/resources/canvas_medical.svg',
    type: 'Productivity',
  },
  {
    id: 'care_quality_commission',
    name: 'Care Quality Commission',
    iconPath: '/integrations/coil_connectors/resources/care_quality_commission/resources/care_quality_commission.svg',
    type: 'HumanResources',
  },
  {
    id: 'centercode',
    name: 'Centercode',
    iconPath: '/integrations/coil_connectors/resources/centercode/resources/centercode.svg',
    type: 'Engineering',
  },
  {
    id: 'cimis',
    name: 'Cimis',
    iconPath: '/integrations/coil_connectors/resources/cimis/resources/cimis.svg',
    type: 'Productivity',
  },
  {
    id: 'cin7',
    name: 'Cin7',
    iconPath: '/integrations/coil_connectors/resources/cin7/resources/cin7.svg',
    type: 'Productivity',
  },
  {
    id: 'cj_affiliate',
    name: 'Cj Affiliate',
    iconPath: '/integrations/coil_connectors/resources/cj_affiliate/resources/cj_affiliate.svg',
    type: 'Marketing',
  },
  {
    id: 'collibra',
    name: 'Collibra',
    iconPath: '/integrations/coil_connectors/resources/collibra/resources/collibra.svg',
    type: 'Engineering',
  },
  {
    id: 'cordial',
    name: 'Cordial',
    iconPath: '/integrations/coil_connectors/resources/cordial/resources/cordial.svg',
    type: 'Productivity',
  },
  {
    id: 'culture_amp',
    name: 'Culture Amp',
    iconPath: '/integrations/coil_connectors/resources/culture_amp/resources/culture_amp.svg',
    type: 'HumanResources',
  },
  {
    id: 'd2l_brightspace',
    name: 'D2L Brightspace',
    iconPath: '/integrations/coil_connectors/resources/d2l_brightspace/resources/d2l_brightspace.svg',
    type: 'Productivity',
  },
  {
    id: 'db2',
    name: 'Db2 for LUW',
    iconPath: '/integrations/db2/resources/logos/db2.png',
    type: 'Database',
  },
  {
    id: 'deel',
    name: 'Deel',
    iconPath: '/integrations/coil_connectors/resources/deel/resources/deel.svg',
    type: 'HumanResources',
  },
  {
    id: 'deliverr',
    name: 'Deliverr',
    iconPath: '/integrations/coil_connectors/resources/deliverr/resources/deliverr.svg',
    type: 'HumanResources',
  },
  {
    id: 'email',
    name: 'Email',
    iconPath: '/integrations/email/resources/email.svg',
    type: 'File',
  },
  {
    id: 'expeditors',
    name: 'Expeditors',
    iconPath: '/integrations/coil_connectors/resources/expeditors/resources/expeditors.svg',
    type: 'Productivity',
  },
  {
    id: 'ezyvet',
    name: 'EzyVet',
    iconPath: '/integrations/coil_connectors/resources/ezyvet/resources/ezyvet.svg',
    type: 'Productivity',
  },
  {
    id: 'ftp',
    name: 'FTP',
    iconPath: '/integrations/ftp/resources/ftp.svg',
    type: 'File',
  },
  {
    id: 'figma',
    name: 'Figma',
    iconPath: '/integrations/coil_connectors/resources/figma/resources/figma.svg',
    type: 'Productivity',
  },
  {
    id: 'financial_force',
    name: 'FinancialForce',
    iconPath: '/integrations/salesforce/resources/financial_force.svg',
    type: 'API',
  },
  {
    id: 'financial_force_sandbox',
    name: 'FinancialForce sandbox',
    iconPath: '/integrations/salesforce/resources/financial_force.svg',
    type: 'API',
  },
  {
    id: 'formassembly',
    name: 'FormAssembly',
    iconPath: '/integrations/coil_connectors/resources/formassembly/resources/formassembly.svg',
    type: 'Security',
  },
  {
    id: 'fred',
    name: 'Fred',
    iconPath: '/integrations/coil_connectors/resources/fred/resources/fred.svg',
    type: 'Database',
  },
  {
    id: 'freightview',
    name: 'Freightview',
    iconPath: '/integrations/coil_connectors/resources/freightview/resources/freightview.svg',
    type: 'Productivity',
  },
  {
    id: 'freshdesk_contact_center',
    name: 'Freshdesk Contact Center',
    iconPath: '/integrations/coil_connectors/resources/freshdesk_contact_center/resources/freshdesk_contact_center.svg',
    type: 'Support',
  },
  {
    id: 'freshservice_lite',
    name: 'Freshservice_lite',
    iconPath: '/integrations/coil_connectors/resources/freshservice_lite/resources/freshservice_lite.svg',
    type: 'Engineering',
  },
  {
    id: 'freshsuccess',
    name: 'Freshsuccess',
    iconPath: '/integrations/coil_connectors/resources/freshsuccess/resources/freshsuccess.svg',
    type: 'Support',
  },
  {
    id: 'g2',
    name: 'G2',
    iconPath: '/integrations/coil_connectors/resources/g2/resources/g2.svg',
    type: 'Sales',
  },
  {
    id: 'gainsight',
    name: 'Gainsight',
    iconPath: '/integrations/coil_connectors/resources/gainsight/resources/gainsight.svg',
    type: 'Sales',
  },
  {
    id: 'github_enterprise',
    name: 'GitHub Enterprise',
    iconPath: '/integrations/coil_connectors/resources/github_enterprise/resources/github_enterprise.svg',
    type: 'Productivity',
  },
  {
    id: 'gusto',
    name: 'Gusto',
    iconPath: '/integrations/coil_connectors/resources/gusto/resources/gusto.svg',
    type: 'HumanResources',
  },
  {
    id: 'hotjar',
    name: 'Hotjar',
    iconPath: '/integrations/coil_connectors/resources/hotjar/resources/hotjar.svg',
    type: 'HumanResources',
  },
  {
    id: 'humanitix',
    name: 'Humanitix',
    iconPath: '/integrations/coil_connectors/resources/humanitix/resources/humanitix.svg',
    type: 'Marketing',
  },
  {
    id: 'hyperswitch',
    name: 'Hyperswitch',
    iconPath: '/integrations/coil_connectors/resources/hyperswitch/resources/hyperswitch.svg',
    type: 'Productivity',
  },
  {
    id: 'intellishop',
    name: 'IntelliShop',
    iconPath: '/integrations/coil_connectors/resources/intellishop/resources/intellishop.svg',
    type: 'Marketing',
  },
  {
    id: 'justcall',
    name: 'JustCall',
    iconPath: '/integrations/coil_connectors/resources/justcall/resources/justcall.svg',
    type: 'Marketing',
  },
  {
    id: 'katana',
    name: 'Katana',
    iconPath: '/integrations/coil_connectors/resources/katana/resources/katana.svg',
    type: 'Marketing',
  },
  {
    id: 'livevox',
    name: 'LiveVox',
    iconPath: '/integrations/coil_connectors/resources/livevox/resources/livevox.svg',
    type: 'Productivity',
  },
  {
    id: 'linkedin_ads_looker',
    name: 'Looker - LinkedIn Ad Analytics',
    iconPath: '/integrations/linkedin/resources/linkedin_ads.svg',
    type: 'API',
  },
  {
    id: 'mailshake',
    name: 'Mailshake',
    iconPath: '/integrations/coil_connectors/resources/mailshake/resources/mailshake.svg',
    type: 'Marketing',
  },
  {
    id: 'mambu',
    name: 'Mambu',
    iconPath: '/integrations/coil_connectors/resources/mambu/resources/mambu.svg',
    type: 'Finance',
  },
  {
    id: 'marchex',
    name: 'Marchex',
    iconPath: '/integrations/coil_connectors/resources/marchex/resources/marchex.svg',
    type: 'Marketing',
  },
  {
    id: 'marin',
    name: 'Marin Software',
    iconPath: '/integrations/marin/resources/logo.png',
    type: 'Marketing',
  },
  {
    id: 'meltwater',
    name: 'Meltwater',
    iconPath: '/integrations/coil_connectors/resources/meltwater/resources/meltwater.svg',
    type: 'Productivity',
  },
  {
    id: 'mention',
    name: 'Mention',
    iconPath: '/integrations/coil_connectors/resources/mention/resources/mention.svg',
    type: 'Marketing',
  },
  {
    id: 'microsoft_contacts',
    name: 'Microsoft Contacts',
    iconPath: '/integrations/coil_connectors/resources/microsoft_contacts/resources/microsoft_contacts.svg',
    type: 'Productivity',
  },
  {
    id: 'microsoft_teams',
    name: 'Microsoft Teams',
    iconPath: '/integrations/coil_connectors/resources/microsoft_teams/resources/microsoft_teams.svg',
    type: 'Productivity',
  },
  {
    id: 'miro',
    name: 'Miro',
    iconPath: '/integrations/coil_connectors/resources/miro/resources/miro.svg',
    type: 'Productivity',
  },
  {
    id: 'monte_carlo',
    name: 'Monte Carlo',
    iconPath: '/integrations/coil_connectors/resources/monte_carlo/resources/monte_carlo.svg',
    type: 'Productivity',
  },
  {
    id: 'myob',
    name: 'Myob',
    iconPath: '/integrations/coil_connectors/resources/myob/resources/myob.svg',
    type: 'Finance',
  },
  {
    id: 'newsapi',
    name: 'News API',
    iconPath: '/integrations/coil_connectors/resources/newsapi/resources/newsapi.svg',
    type: 'Productivity',
  },
  {
    id: 'nicereply',
    name: 'Nicereply',
    iconPath: '/integrations/coil_connectors/resources/nicereply/resources/nicereply.svg',
    type: 'Productivity',
  },
  {
    id: 'pacific_gas_and_electric',
    name: 'Pacific Gas and Electric',
    iconPath: '/integrations/coil_connectors/resources/pacific_gas_and_electric/resources/pacific_gas_and_electric.svg',
    type: 'Engineering',
  },
  {
    id: 'pipe17',
    name: 'Pipe17',
    iconPath: '/integrations/coil_connectors/resources/pipe17/resources/pipe17.svg',
    type: 'Productivity',
  },
  {
    id: 'pivotal_tracker',
    name: 'Pivotal Tracker',
    iconPath: '/integrations/coil_connectors/resources/pivotal_tracker/resources/pivotal_tracker.svg',
    type: 'Productivity',
  },
  {
    id: 'piwik',
    name: 'Piwik',
    iconPath: '/integrations/coil_connectors/resources/piwik/resources/piwik.svg',
    type: 'Marketing',
  },
  {
    id: 'playvox',
    name: 'Playvox',
    iconPath: '/integrations/coil_connectors/resources/playvox/resources/playvox.svg',
    type: 'Productivity',
  },
  {
    id: 'postmark',
    name: 'Postmark',
    iconPath: '/integrations/coil_connectors/resources/postmark/resources/postmark.svg',
    type: 'Marketing',
  },
  {
    id: 'postscript',
    name: 'Postscript',
    iconPath: '/integrations/coil_connectors/resources/postscript/resources/postscript.svg',
    type: 'Engineering',
  },
  {
    id: 'proofpoint',
    name: 'Proofpoint',
    iconPath: '/integrations/coil_connectors/resources/proofpoint/resources/proofpoint.svg',
    type: 'Security',
  },
  {
    id: 'qonto',
    name: 'Qonto',
    iconPath: '/integrations/coil_connectors/resources/qonto/resources/qonto.svg',
    type: 'Finance',
  },
  {
    id: 'reltio',
    name: 'Reltio',
    iconPath: '/integrations/coil_connectors/resources/reltio/resources/reltio.svg',
    type: 'Productivity',
  },
  {
    id: 'revel',
    name: 'Revel Systems',
    iconPath: '/integrations/coil_connectors/resources/revel/resources/revel.svg',
    type: 'Finance',
  },
  {
    id: 'revenuecat',
    name: 'Revenuecat',
    iconPath: '/integrations/coil_connectors/resources/revenuecat/resources/revenuecat.svg',
    type: 'Productivity',
  },
  {
    id: 'reviewpro',
    name: 'ReviewPro',
    iconPath: '/integrations/coil_connectors/resources/reviewpro/resources/reviewpro.svg',
    type: 'Productivity',
  },
  {
    id: 'rivaliq',
    name: 'RivalIQ',
    iconPath: '/integrations/coil_connectors/resources/rivaliq/resources/rivaliq.svg',
    type: 'Productivity',
  },
  {
    id: 'sap_s4hana',
    name: 'SAP S/4',
    iconPath: '/integrations/sap_s4hana/resources/sap-s4hana.svg',
    type: 'Finance',
  },
  {
    id: 'sftp',
    name: 'SFTP',
    iconPath: '/integrations/sftp/resources/sftp.svg',
    type: 'File',
  },
  {
    id: 'salesforce_for_looker',
    name: 'Salesforce for Looker',
    iconPath: '/integrations/salesforce/resources/salesforce.svg',
    type: 'API',
  },
  {
    id: 'salesforce_for_looker_sandbox',
    name: 'Salesforce for Looker Sandbox',
    iconPath: '/integrations/salesforce/resources/salesforce_sandbox.png',
    type: 'API',
  },
  {
    id: 'salsify',
    name: 'Salsify',
    iconPath: '/integrations/coil_connectors/resources/salsify/resources/salsify.svg',
    type: 'Productivity',
  },
  {
    id: 'seismic',
    name: 'Seismic',
    iconPath: '/integrations/coil_connectors/resources/seismic/resources/seismic.svg',
    type: 'Marketing',
  },
  {
    id: 'semrush',
    name: 'Semrush',
    iconPath: '/integrations/coil_connectors/resources/semrush/resources/semrush.svg',
    type: 'Marketing',
  },
  {
    id: 'sensormatic',
    name: 'Sensormatic ShopperTrak',
    iconPath: '/integrations/coil_connectors/resources/sensormatic/resources/sensormatic.svg',
    type: 'Productivity',
  },
  {
    id: 'sharetribe',
    name: 'Sharetribe',
    iconPath: '/integrations/coil_connectors/resources/sharetribe/resources/sharetribe.svg',
    type: 'Engineering',
  },
  {
    id: 'shift4shop',
    name: 'Shift4Shop',
    iconPath: '/integrations/coil_connectors/resources/shift4shop/resources/shift4shop.svg',
    type: 'Sales',
  },
  {
    id: 'shortio',
    name: 'Short.io',
    iconPath: '/integrations/coil_connectors/resources/shortio/resources/shortio_1.svg',
    type: 'Productivity',
  },
  {
    id: 'similarweb',
    name: 'Similarweb',
    iconPath: '/integrations/coil_connectors/resources/similarweb/resources/similarweb.svg',
    type: 'Engineering',
  },
  {
    id: 'dummy_postgres',
    name: 'Simple Postgres',
    iconPath: '/integrations/postgres2020/resources/postgres.svg',
    type: 'Database',
  },
  {
    id: 'simplecast',
    name: 'Simplecast',
    iconPath: '/integrations/coil_connectors/resources/simplecast/resources/simplecast.svg',
    type: 'Productivity',
  },
  {
    id: 'skai',
    name: 'Skai',
    iconPath: '/integrations/coil_connectors/resources/skai/resources/skai.svg',
    type: 'Marketing',
  },
  {
    id: 'slab',
    name: 'Slab',
    iconPath: '/integrations/coil_connectors/resources/slab/resources/slab.svg',
    type: 'Productivity',
  },
  {
    id: 'snappy',
    name: 'Snappy',
    iconPath: '/integrations/coil_connectors/resources/snappy/resources/snappy.svg',
    type: 'Productivity',
  },
  {
    id: 'spotify_ads',
    name: 'Spotify Ads',
    iconPath: '/integrations/spotify_ads/resources/spotify_icon.png',
    type: 'Marketing',
  },
  {
    id: 'sprinklr',
    name: 'Sprinklr',
    iconPath: '/integrations/coil_connectors/resources/sprinklr/resources/sprinklr.svg',
    type: 'Productivity',
  },
  {
    id: 'tune_pay',
    name: 'TUNE Pay',
    iconPath: '/integrations/coil_connectors/resources/tune_pay/resources/tune_pay.svg',
    type: 'Finance',
  },
  {
    id: 'ticketmaster',
    name: 'Ticketmaster',
    iconPath: '/integrations/coil_connectors/resources/ticketmaster/resources/ticketmaster.svg',
    type: 'Engineering',
  },
  {
    id: 'toast',
    name: 'Toast',
    iconPath: '/integrations/coil_connectors/resources/toast/resources/toast.svg',
    type: 'Finance',
  },
  {
    id: 'upwork',
    name: 'Upwork',
    iconPath: '/integrations/coil_connectors/resources/upwork/resources/upwork.svg',
    type: 'Productivity',
  },
  {
    id: 'veevavault',
    name: 'VeevaVault',
    iconPath: '/integrations/coil_connectors/resources/veevavault/resources/veevavault.svg',
    type: 'Productivity',
  },
  {
    id: 'web_scraper',
    name: 'Web Scraper',
    iconPath: '/integrations/coil_connectors/resources/web_scraper/resources/web_scraper.svg',
    type: 'Engineering',
  },
  {
    id: 'wherefour',
    name: 'Wherefour',
    iconPath: '/integrations/coil_connectors/resources/wherefour/resources/wherefour.svg',
    type: 'Productivity',
  },
  {
    id: 'wise',
    name: 'Wise',
    iconPath: '/integrations/coil_connectors/resources/wise/resources/wise.svg',
    type: 'Finance',
  },
  {
    id: 'wootric',
    name: 'Wootric',
    iconPath: '/integrations/coil_connectors/resources/wootric/resources/wootric.svg',
    type: 'Productivity',
  },
  {
    id: 'workflowmax',
    name: 'Workflowmax',
    iconPath: '/integrations/coil_connectors/resources/workflowmax/resources/workflowmax.svg',
    type: 'Productivity',
  },
  {
    id: 'zenefits',
    name: 'Zenefits',
    iconPath: '/integrations/coil_connectors/resources/zenefits/resources/zenefits.svg',
    type: 'HumanResources',
  },
  {
    id: 'zenloop',
    name: 'Zenloop',
    iconPath: '/integrations/coil_connectors/resources/zenloop/resources/zenloop.svg',
    type: 'Marketing',
  },
  {
    id: 'zingtree',
    name: 'Zingtree',
    iconPath: '/integrations/coil_connectors/resources/zingtree/resources/zingtree.svg',
    type: 'Support',
  },
  {
    id: 'zoominfo',
    name: 'Zoominfo',
    iconPath: '/integrations/coil_connectors/resources/zoominfo/resources/zoominfo.svg',
    type: 'Productivity',
  },
  {
    id: 'economic',
    name: 'e-conomic',
    iconPath: '/integrations/coil_connectors/resources/economic/resources/economic.svg',
    type: 'Finance',
  },
  {
    id: 'ironsource',
    name: 'ironSource',
    iconPath: '/integrations/coil_connectors/resources/ironsource/resources/ironsource.svg',
    type: 'Productivity',
  },
];

const working = [
  'Salesforce',
  'Google Ads',
  'Shopify',
  'Amazon S3',
  'Facebook Ads',
  'Google Analytics 4',
  'Google Cloud Functions',
  'Postgres RDS',
  'Google Sheets',
  'SQL Server',
  'Fivetran Log',
  'Azure Blob Storage',
  'HubSpot',
  'AWS Lambda',
  'Tiktok Ads',
  'Webhooks',
  'Klaviyo',
  'Google Cloud Storage',
  'Stripe',
  'Azure SQL Database',
  'Aurora Postgres',
  'MongoDB',
  'QuickBooks',
  'Microsoft Advertising',
  'YouTube Analytics',
  'Facebook Pages',
  'Salesforce sandbox',
  'MySQL RDS',
  'Workday RaaS',
  'Aurora MySQL',
  'GitHub',
  'Snowflake',
  'NetSuite SuiteAnalytics',
  'Google Cloud PostgreSQL',
  'LinkedIn Ad Analytics',
  'Azure Functions',
  'Xero',
  'Instagram Business',
  'Jira',
  'Google Search Console',
  'Pinterest Ads',
  'Zendesk Support',
  'Postgres',
  'MySQL',
  'Oracle',
  'Salesforce Marketing Cloud',
  'DynamoDB',
  'LinkedIn Company Pages',
  'High-Volume Agent SQL Server',
  'Google Display & Video 360',
  'Snapchat Ads',
  'SQL Server RDS',
  'Amazon Ads',
  'Intercom',
  'Apple App Store',
  'Heroku Postgres',
  'Typeform',
  'BigQuery',
  'Google Analytics 4 Export',
  'Microsoft Dynamics 365 CRM',
  'Mailchimp',
  'Recharge',
  'Pendo',
  'Twitter Ads',
  'Google Play',
  'Amplitude',
  'Asana',
  'Google Campaign Manager 360',
  'Iterable',
  'Pipedrive',
  'High-Volume Agent Oracle',
  'Salesforce Commerce Cloud',
  'Mixpanel',
  'Twitter Organic',
  'Google Cloud MySQL',
  'Airtable',
  'Qualtrics',
  'Outreach',
  'Oracle RAC',
  'Sage Intacct',
  'Marketo',
  'Segment',
  'Twilio',
  'Oracle Fusion Cloud Applications - FSCM(ERP & SCM)',
  'Workday HCM',
  'Apple Search Ads',
  'SurveyMonkey',
  'MariaDB',
  'Google Analytics (MCF)',
  'Oracle RDS',
  'Delighted',
  'Microsoft Lists',
  'Criteo',
  'SendGrid',
  'Okta',
  'Braze',
  'ActiveCampaign',
  'Stripe test mode',
  'Gong',
  'Pardot',
  'WooCommerce',
  'Google Ad Manager',
  'Greenhouse',
  'Square',
  'Bamboo HR',
  'Gainsight Customer Success',
  'ServiceNow',
  'Impact',
  'Adobe Analytics',
  'Azure PostgreSQL Database',
  'ClickUp',
  'AppsFlyer',
  'Freshdesk',
  'MongoDB Sharded',
  'Azure SQL Managed Instance',
  'Open Exchange Rates',
  'Taboola',
  'Paypal',
  'Zuora Sandbox',
  'MariaDB RDS',
  'Braintree',
  'Front',
  'Reddit Ads',
  'Zoho CRM',
  'Zendesk Chat',
  'Salesloft',
  'High Volume Agent Db2 for i',
  'Confluent Cloud',
  'Zuora',
  'RingCentral',
  'Kustomer',
  'OneDrive',
  'Branch',
  'Anaplan',
  'Coupa',
  'Eloqua',
  'Outbrain',
  'Alchemer',
  'Recurly',
  'Oracle Fusion Cloud Applications - CRM',
  'ADP Workforce Now',
  'Amazon Kinesis Firehose',
  'Gorgias',
  'Azure MySQL',
  'Oracle Fusion Cloud Applications - HCM',
  'Firebase',
  'Google Analytics 360',
  'Drift',
  'Talkdesk',
  'Adobe Analytics Data Feed',
  'Help Scout',
  'Snowplow',
  'Amazon DocumentDB',
  'Lattice',
  'The Trade Desk',
  'High-Volume Agent SAP ECC on Oracle with NetWeaver',
  'Wrike',
  'Freshservice',
  'Productboard',
  'Apache Kafka',
  'Lever',
  'Oracle EBS',
  'ShipStation',
  'Mavenlink',
  'Medallia',
  'High-Volume Agent SAP ECC on Db2 for i',
  'Lightspeed Retail',
  'Brex',
  'SAP Concur',
  'EasyPost',
  'Heap',
  'Azure MariaDB',
  'Sailthru',
  'Magento MySQL',
  'Mailgun',
  'Open Distro',
  'Samsara',
  'Elastic Cloud',
  'ChannelAdvisor',
  'AdRoll',
  '360Learning',
  'Braintree Sandbox',
  'Crossbeam',
  'Optimizely',
  'Self Hosted Elasticsearch',
  'SAP Business ByDesign',
  'AWS Inventory',
  'Mandrill',
  'Opensearch',
  'High-Volume Agent SAP ECC on Oracle',
  'SkuVault',
  'When I Work',
  'Azure Event Hubs',
  'Height',
  'Paypal Sandbox',
  'Khoros Care',
  'Azure Service Bus',
  'Adjust',
  'AWS MSK',
  'Dixa',
  'Drip',
  'Orbit',
  'Splunk',
  'Ada',
  'AWS CloudTrail',
  'Amazon CloudFront',
  'Guru',
  'Lessonly',
  'Magento MySQL RDS',
  'Rollbar',
  'SCORM',
  'Sendinblue',
  'Squarespace',
  'UserVoice',
  'Verizon Media',
  'Google Cloud SQL - SQL Server',
  'Microsoft Dynamics 365 F/O',
  'Fountain',
  'Heroku Kafka',
  'RetailNext',
  'Zendesk Sunshine',
  'Retently',
  'Zendesk Sell',
  'Cin7',
  'Email',
  'FTP',
  'FinancialForce',
  'Marin Software',
  'SFTP',
];

export const connections = allconnections.filter((obj) => working.includes(obj.name));
